import { debounce } from "./util";

const config = {
  webm: {
    1: {
      smallest:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-1-smallest-v2.webm",
      smaller:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-1-smaller-v2.webm",
      normal:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-1-v2.webm",
    },
    2: {
      smallest:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-2-smallest-v2.webm",
      smaller:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-2-smaller-v2.webm",
      normal:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-2-v2.webm",
    },
    3: {
      smallest:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-3-smallest.webm",
      smaller:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-3-smaller.webm",
      normal:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-3.webm",
    },
  },

  mp4: {
    1: {
      smallest:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-1-smallest-v2.mp4",
      smaller:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-1-smaller-v2.mp4",
      normal:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-1-v2.mp4",
    },
    2: {
      smallest:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-2-smallest-v2.mp4",
      smaller:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-2-smaller-v2.mp4",
      normal:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-2-v2.mp4",
    },
    3: {
      smallest:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-3_smallest.mp4",
      smaller:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-3_smaller.mp4",
      normal:
        "https://assets.theoceancleanup.com/videos/TOC-animation-home-3.mp4",
    },
  },
};

function setCorrectVideoSize(video, { smallest, smaller, normal }) {
  if (window.innerWidth < 1000) {
    video.setAttribute("src", smallest);
  } else if (window.innerWidth < 1500) {
    video.setAttribute("src", smaller);
  } else {
    video.setAttribute("src", normal);
  }
}

export function enhancer(video) {
  const videoIndex = video.getAttribute("data-index");

  // Update background color to match the mp4 color.

  // Is a Apple mobile device.
  const isiOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // Is a Apple desktop device.
  const isOSX =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window.safari || window.safari.pushNotification);

  if (isiOS) {
    document.documentElement.style.setProperty(
      "--secondary-color-dark",
      "#00283C",
    );
  }
  if (isOSX) {
    document.documentElement.style.setProperty(
      "--secondary-color-dark",
      "#002D44",
    );
  }

  // Use correct config based on type of browser.
  // Needed to server a transparent webm whenever possible.
  const browserMatchingConfig = isiOS || isOSX ? config.mp4 : config.webm;

  setCorrectVideoSize(video, browserMatchingConfig[videoIndex]);

  // Update video source on resize.

  /**
   * Escape if the window width didn't change.
   * Prevents iOS scrolling and collapsing the url bar from triggering a resize event.
   */
  let windowWidth = window.innerWidth;
  const ignoreIosScroll = (fn) => () => {
    if (windowWidth === window.innerWidth) {
      return;
    }

    windowWidth = window.innerWidth;
    fn();
  };

  const handleResize = () => {
    setCorrectVideoSize(video, browserMatchingConfig[videoIndex]);
  };
  const resizeHandler = debounce(ignoreIosScroll(handleResize), 500);
  window.addEventListener("resize", resizeHandler);
}
