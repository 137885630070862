import { subscribe } from "../observer";
import {
  trashTrackerResetData,
  trashTrackerUpdateData,
} from "../observer-subjects";

/**
 * Called by observer.
 * Update contents and visibility of the element.
 */
const handleDataUpdate =
  (containerElement, scoreElement) =>
  ({ probability }) => {
    scoreElement.innerHTML = probability;
    containerElement.setAttribute("data-show", "true");
  };

/**
 * Called by observer.
 * Hide element on reset.
 */
const handleReset = (containerElement) => () => {
  containerElement.setAttribute("data-show", "false");
};

export const enhancer = (containerElement) => {
  const scoreElement = containerElement.querySelector(".js-probability-score");

  subscribe(
    trashTrackerUpdateData,
    handleDataUpdate(containerElement, scoreElement),
  );
  subscribe(trashTrackerResetData, handleReset(containerElement));
};
