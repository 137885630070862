import { subscribe } from "../observer";
import {
  trashTrackerHasSubmitted,
  trashTrackerResetForm,
} from "../observer-subjects";

/**
 * Called by observer.
 * Show location title and set correct title.
 */
const handleSubmit =
  (containerElement, titleElement) =>
  ({ location }) => {
    containerElement.setAttribute("data-visible", "true");
    titleElement.innerHTML = location;
  };

/**
 * Called by observer.
 * Hide location title and clear content
 */
const handleFormReset = (containerElement, titleElement) => () => {
  containerElement.setAttribute("data-visible", "false");
  titleElement.innerHTML = "";
};

export const enhancer = (element) => {
  const titleElement = element.querySelector(".js-location-title");

  subscribe(trashTrackerHasSubmitted, handleSubmit(element, titleElement));
  subscribe(trashTrackerResetForm, handleFormReset(element, titleElement));
};
