import secondsToMinutes from "../../utils/seconds-to-minutes";
import utcToDisplayDateTime from "../../utils/utc-to-display-date-time";

/**
 * Format camelCase to kebab-case.
 * Code from: https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707.
 */
const camelToKebab = (input) =>
  input.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();

/**
 * Format the results data that is passed to the info box.
 */
const formatResults = (results) => (key) => ({
  type: camelToKebab(key),
  value: results[key],
  displayValue: results[key],
});

/**
 * Add new value to total value.
 */
const add = (total, count) => total + count;

const preferredOrder = [
  "hard",
  "soft",
  "bottle",
  "clothing",
  "foam",
  "organic",
  "non-plastic",
  "other",
];

/**
 * Sort based on the preferred order.
 */
const sortOnType = (a, b) =>
  preferredOrder.indexOf(a.type) - preferredOrder.indexOf(b.type);

/**
 * Format coordinate to only show 6 numbers after the (.).
 */
export const formatCoordinate = (item) => Number(item).toFixed(6);

/**
 * Filter if item contains a value more than 0.
 */
const hasValue = (item) => item.value;

/**
 * Format API data to Application ready data.
 */
export default (item) => {
  // Destructure item.
  const { id, type } = item;
  const {
    results,
    total_seconds: totalSeconds,
    start_time: startTime,
  } = item.attributes;
  const { longitude, latitude } = item.attributes.location;

  // Create new format.
  return {
    id: String(id),
    type,
    geometry: {
      type: "Point",
      coordinates: [longitude, latitude],
    },
    properties: {
      id: String(id),
      displayId: String(id).slice(0, 6),
      type: item.attributes.type,
      latLong: `${latitude}, ${longitude}`,
      displayLatLong: `${formatCoordinate(latitude)}, ${formatCoordinate(
        longitude,
      )}`,
      durationInMinutes: secondsToMinutes(totalSeconds),
      dateTime: utcToDisplayDateTime(startTime),
      utcDate: startTime,
      results: Object.keys(results)
        .map(formatResults(results))
        .sort(sortOnType)
        .filter(hasValue),
      totalItemsCount: Object.values(results).reduce(add, 0),
    },
  };
};
