/* eslint-disable import/first, import/no-cycle, import/newline-after-import */

// External packages
import Promise from "promise-polyfill";
if (typeof window.Promise === "undefined") {
  window.Promise = Promise;
}
import "whatwg-fetch";
import "formdata-polyfill";
import "intersection-observer";
import "focus-visible";
import "classlist-polyfill";
import "url-search-params-polyfill";
import "./polyfills/array-findindex";
import "./polyfills/array-includes";
import "./polyfills/object-values-entries";
import screenfull from "screenfull";
import { handle, enhance } from "@grrr/hansel";

// Polyfills and modules
import { onDomReady } from "./modules/ready";
import disableHoverStylesOnScroll from "./modules/disable-hover-styles-on-scroll";
import rafPolyfill from "./polyfills/request-animation-frame";
import cookieConsent from "./modules/cookie-consent";

// Enhancers
import { enhancer as activeItemIndicator } from "./modules/active-item-indicator";
import { enhancer as appearWhenScrolledPast } from "./modules/appear-when-scrolled-past";
import { enhancer as autoplayVideo } from "./modules/autoplay-video";
import { enhancer as awardsBlock } from "./modules/awards-block";
import { enhancer as brandguideNavigation } from "./modules/brandguide-navigation";
import { enhancer as centerCardObserver } from "./modules/center-card-observer";
import { enhancer as cleanupMapVideoLoader } from "./modules/cleanup-map-video-loader";
import { enhancer as citizenScienceMap } from "./modules/citizen-science-map/index";
import { enhancer as checkHasValue } from "./modules/check-has-value";
import { enhancer as citizenScienceClusterInfo } from "./modules/citizen-science-map/citizen-science-cluster-info";
import { enhancer as citizenScienceMarkerInfo } from "./modules/citizen-science-map/citizen-science-marker-info";
import { enhancer as citizenScienceMeta } from "./modules/citizen-science-map/citizen-science-meta";
import { enhancer as citizenScienceSideBar } from "./modules/citizen-science-map/citizen-science-side-bar";
import { enhancer as cleanupMapScroller } from "./modules/cleanup-map-scroller";
import { enhancer as contactFormConditionals } from "./modules/contact-form-conditionals";
import { enhancer as contactFormSubmit } from "./modules/contact-form-submit";
import { enhancer as defaultMapInfoBox } from "./modules/mapbox/map-side-bar";
import { enhancer as descriptionToggle } from "./modules/description-toggle";
import { enhancer as donateForm } from "./modules/donate-form";
import { enhancer as donateFormOld } from "./modules/donate-form-old";
import { enhancer as donateFormCountrySelect } from "./modules/donate-form-country-select";
import { enhancer as donateFormCurrencyPreselect } from "./modules/donate-form-currency-preselect";
import { enhancer as donateSuccess } from "./modules/donate-success";
import { enhancer as donateTriggerBar } from "./modules/donate-trigger-bar";
import { enhancer as emailShare } from "./modules/email-share";
import { enhancer as emailSuggestions } from "./modules/email-suggestions";
import { enhancer as emailPopupWhenUserLeaves } from "./modules/email-popup-when-user-leaves";
import { enhancer as expandButton } from "./modules/expand-button";
import { enhancer as explanationSteps } from "./modules/explanation-steps";
import { enhancer as faqSearch } from "./modules/faq-search";
import { enhancer as filterNavActive } from "./modules/filter-nav";
import { enhancer as headroomEnhancer } from "./modules/headroom";
import { enhancer as heroSlider } from "./modules/hero-slider";
import { enhancer as imageComparison } from "./modules/image-comparison";
import { enhancer as imageGallery } from "./modules/image-gallery";
import { enhancer as imageSlider } from "./modules/image-slider";
import { enhancer as inPageNavigation } from "./modules/in-page-navigation";
import { enhancer as inPageNavigationItem } from "./modules/in-page-navigation-item";
import { enhancer as inPageNavigationCurrentItem } from "./modules/in-page-navigation-current-item";
import { enhancer as jobOpeningFormAttachments } from "./modules/job-opening-form-attachments";
import { enhancer as lazyFeed } from "./modules/lazy-feed";
import { enhancer as liveEventBanner } from "./modules/live-event-banner";
import { enhancer as liveEventCountdown } from "./modules/live-event-countdown";
import { enhancer as mapIntro } from "./modules/map-intro";
import { enhancer as mapMeta } from "./modules/map-meta";
import { enhancer as mediaGalleryToggle } from "./modules/media-gallery-toggle";
import { enhancer as mediaSlider } from "./modules/media-slider";
import { enhancer as milestones } from "./modules/milestones";
import { enhancer as newsletterForm } from "./modules/newsletter-form";
import { enhancer as newsSlider } from "./modules/news-slider";
import { enhancer as notifyHeightOnResize } from "./modules/notify-height-on-resize";
import { enhancer as objectFit } from "./modules/object-fit";
import { enhancer as ourProcess } from "./modules/our-process";
import { enhancer as passwordProtectionForm } from "./modules/password-protection-form";
import { enhancer as partnerTimelineScroller } from "./modules/partner-timeline-scroller ";
import { enhancer as plasticJourneyIllustrationHighlight } from "./modules/plastic-journey-illustration-highlight";
import { enhancer as plasticJourneyIllustrationMove } from "./modules/plastic-journey-illustration-move";
import { enhancer as playVideoBasedOnElementsLocation } from "./modules/play-video-based-on-elements-location";
import { enhancer as playVideoIfInView } from "./modules/play-video-if-in-view";
import { enhancer as placeHtmlWithDelay } from "./modules/place-html-with-delay";
import { enhancer as popup } from "./modules/popup";
import { enhancer as quoteSlider } from "./modules/quote-slider";
import { enhancer as repeatVideo } from "./modules/repeat-video";
import { enhancer as scientificPublication } from "./modules/scientific-publication";
import { enhancer as setSpecialColor } from "./modules/set-special-color";
import { enhancer as shareButtons } from "./modules/share-buttons";
import { enhancer as siteBanner } from "./modules/site-banner";
import { enhancer as siteNav } from "./modules/site-nav";
import { enhancer as siteSearch } from "./modules/site-search";
import { enhancer as socialUpdatesSlider } from "./modules/social-updates-slider";
import { enhancer as sourcesMap } from "./modules/sources-map";
import { enhancer as sticky } from "./modules/sticky";
import { enhancer as stickyToc } from "./modules/sticky-toc";
import { enhancer as styleguideTabs } from "./modules/styleguide-tabs";
import { enhancer as supportersSlider } from "./modules/supporters-slider";
import { enhancer as sunglassesInfo } from "./modules/sunglasses-info";
import { trigger as sunglassesInfoTrigger } from "./modules/sunglasses-info";
import { enhancer as sunglassesContentBox } from "./modules/sunglasses-content-box";
import { enhancer as sunglassesStories } from "./modules/sunglasses-stories/index";
import { enhancer as sunglassesStoriesResultsToDOM } from "./modules/sunglasses-stories/results-to-dom";
import { enhancer as sunglassesStoriesSearch } from "./modules/sunglasses-stories/search";
import { enhancer as sunglassesStoriesSlider } from "./modules/sunglasses-stories/slider";
import { enhancer as systemMap } from "./modules/system-map";
import { enhancer as systemsDashboard } from "./modules/systems-dashboard";
import { enhancer as systemsDashboardMeta } from "./modules/systems-dashboard-meta";
import { enhancer as systemsDashboardMetaPreview } from "./modules/systems-dashboard-meta-preview";
import { enhancer as tableScroll } from "./modules/table-scroll";
import { enhancer as teamMemberFilter } from "./modules/team-member-filter";
import { enhancer as newTeamMemberList } from "./modules/new-team-member-list";
import { enhancer as teamMemberList } from "./modules/team-member-list";
import { enhancer as teamMemberShowAllButtonRemove } from "./modules/team-members-show-all";
import { enhancer as teamNationalitiesMap } from "./modules/team-nationalities-map";
import { enhancer as toggleGroup } from "./modules/toggle-group";
import { enhancer as toggleBrandGuideNav } from "./modules/toggle-brand-guide-nav";
import {
  scrollToEnhancer as transcriptScrollTo,
  toggleEnhancer as transcriptToggle,
  transcriptToggleEnhancer,
  transcriptActiveChapterIndicatorEnhancer as transcriptActiveChapterIndicator,
  transcriptActiveChapterObserverEnhancer as transcriptActiveChapterObserver,
} from "./modules/transcript";
import { enhancer as trashTrackerAutocomplete } from "./modules/trash-tracker-map/autocomplete";
import { enhancer as trashTrackerArcticMessage } from "./modules/trash-tracker-map/arctic-message";
import { enhancer as trashTrackerErrorMessage } from "./modules/trash-tracker-map/error-message";
import { enhancer as trashTrackerMap } from "./modules/trash-tracker-map/map";
import { enhancer as trashTrackerLocationForm } from "./modules/trash-tracker-map/location-form";
import { enhancer as trashTrackerShowWhileLoading } from "./modules/trash-tracker-map/show-while-loading";
import { enhancer as trashTrackerProbabilityInfo } from "./modules/trash-tracker-map/probability-info";
import { enhancer as trashTrackerTravelDistanceInfo } from "./modules/trash-tracker-map/travel-distance-info";
import { enhancer as trashTrackerLocationTitle } from "./modules/trash-tracker-map/location-title";
import { enhancer as trashTrackerMobileToggle } from "./modules/trash-tracker-map/mobile-toggle";
import { enhancer as trashTrackerSecondaryButtons } from "./modules/trash-tracker-map/secondary-buttons";

import { enhancer as twitterFeed } from "./modules/twitter-feed";
import { enhancer as twinElementHover } from "./modules/twin-element-hover";
import { enhancer as urlTriggeredNewsletter } from "./modules/url-triggered-newsletter";
import { enhancer as wysiwygUpdateLayout } from "./modules/wysiwyg-update-layout";
import {
  enhancer as zoomableImage,
  finderEnhancer as zoomableImageFinder,
} from "./modules/zoomable-image";

// Handlers
import { handler as boardMemberList } from "./modules/board-member-list";
import { hideInfoBox } from "./modules/mapbox/map-side-bar";
import { handler as classToggler } from "./modules/class-toggler";
import {
  acceptAllCookiesHandler as acceptAllCookies,
  selectCookiePreferenceHandler as selectCookiePreference,
} from "./modules/cookie-consent";
import { handler as faqItem } from "./modules/faq-item";
import { handler as filterNav } from "./modules/filter-nav";
import { handler as imageOverlay } from "./modules/image-overlay";
import { handler as infoTabs } from "./modules/info-tabs";
import {
  rejectSuggestionHandler as emailSuggestionsReject,
  useSuggestionHandler as emailSuggestionsUse,
} from "./modules/email-suggestions";
import { handler as jobFormToggle } from "./modules/job-form-toggle";
import {
  close as lazyAudioEmbedClose,
  play as lazyAudioEmbedPlay,
} from "./modules/lazy-audio-embed";
import {
  close as lazyVideoEmbedClose,
  play as lazyVideoEmbedPlay,
} from "./modules/lazy-video-embed";
import { handler as listExpander } from "./modules/list-expander";
import { handler as mapMetaHandler } from "./modules/map-meta";
import { handler as mapboxMetricsToggler } from "./modules/mapbox-metrics-toggler";
import { handler as mediaTabs } from "./modules/media-tabs";
import { handler as mediaGalleryNavigationItem } from "./modules/media-gallery-navigation-item";
import {
  down as milestonesDown,
  up as milestonesUp,
  select as milestonesSelect,
} from "./modules/milestones";
import { handler as nativeShareButton } from "./modules/native-share-button";
import { openPopupHandler, closePopupHandler } from "./modules/popup";
import { handler as removeElement } from "./modules/remove-element";
import { handler as scrollTo } from "./modules/scroll-to";
import { handler as showOverview } from "./modules/citizen-science-map/citizen-science-side-bar";
import { handler as showAllTeamMembers } from "./modules/show-all-team-members";
import { handler as siteBannerClose } from "./modules/site-banner";
import { toggleHandler as siteNavToggler } from "./modules/site-nav";
import { handler as socialShareButton } from "./modules/social-share-button";
import { handler as socialUpdatesSliderClose } from "./modules/social-updates-slider";
import { handler as sourcesMapPopupButton } from "./modules/sources-map-popup";
import { handler as stickyTocToggle } from "./modules/sticky-toc";
import {
  filterHandler as siteSearchFilter,
  paginateHandler as siteSearchPaginate,
  toggleHandler as siteSearchToggle,
} from "./modules/site-search";
import { handler as sunglassesStoriesSliderHandler } from "./modules/sunglasses-stories/slider";
import { handler as toggleGroupHandler } from "./modules/toggle-group";
import { handler as teamMembersShowAll } from "./modules/team-members-show-all";
import { scrollToHandler as transcriptScrollToHandler } from "./modules/transcript";
import { handler as trashTrackerResetForm } from "./modules/trash-tracker-map/location-form";
import { handler as videoOverlay } from "./modules/video-overlay";
import { handler as wordDefinition } from "./modules/word-definition";
import { handler as zoomToLatestSurvey } from "./modules/citizen-science-map/mapbox-layers";

// Old modules
import scrollListener from "./modules/scroll-listener";
import responsive from "./modules/responsive";
import iframeLazyLoader from "./modules/iframe-lazyloader";

const executeOnReady = () => {
  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  scrollListener();
  responsive();
  iframeLazyLoader();
  disableHoverStylesOnScroll();
  cookieConsent();

  if (screenfull.isEnabled) {
    screenfull.onchange((e) => {
      document.body.classList.toggle("is-fullscreen", screenfull.isFullscreen);
    });
  }
};

export const ENHANCERS = {
  activeItemIndicator,
  appearWhenScrolledPast,
  autoplayVideo,
  awardsBlock,
  brandguideNavigation,
  centerCardObserver,
  cleanupMapVideoLoader,
  checkHasValue,
  citizenScienceMap,
  citizenScienceClusterInfo,
  citizenScienceMarkerInfo,
  citizenScienceMeta,
  citizenScienceSideBar,
  cleanupMapScroller,
  contactFormConditionals,
  contactFormSubmit,
  defaultMapInfoBox,
  descriptionToggle,
  donateForm,
  donateFormOld,
  donateFormCountrySelect,
  donateFormCurrencyPreselect,
  donateTriggerBar,
  donateSuccess,
  emailShare,
  emailPopupWhenUserLeaves,
  emailSuggestions,
  expandButton,
  explanationSteps,
  faqSearch,
  filterNavActive,
  headroomEnhancer,
  heroSlider,
  imageComparison,
  imageGallery,
  imageSlider,
  inPageNavigation,
  inPageNavigationItem,
  inPageNavigationCurrentItem,
  jobOpeningFormAttachments,
  lazyFeed,
  liveEventBanner,
  liveEventCountdown,
  mapIntro,
  mapMeta,
  mediaGalleryToggle,
  mediaSlider,
  milestones,
  newsletterForm,
  newTeamMemberList,
  newsSlider,
  notifyHeightOnResize,
  objectFit,
  ourProcess,
  passwordProtectionForm,
  partnerTimelineScroller,
  plasticJourneyIllustrationHighlight,
  plasticJourneyIllustrationMove,
  playVideoBasedOnElementsLocation,
  playVideoIfInView,
  placeHtmlWithDelay,
  popup,
  quoteSlider,
  repeatVideo,
  scientificPublication,
  setSpecialColor,
  shareButtons,
  siteBanner,
  siteNav,
  siteSearch,
  socialUpdatesSlider,
  sourcesMap,
  sticky,
  stickyToc,
  styleguideTabs,
  supportersSlider,
  sunglassesInfo,
  sunglassesInfoTrigger,
  sunglassesContentBox,
  sunglassesStories,
  sunglassesStoriesResultsToDOM,
  sunglassesStoriesSearch,
  sunglassesStoriesSlider,
  systemMap,
  systemsDashboard,
  systemsDashboardMeta,
  systemsDashboardMetaPreview,
  tableScroll,
  teamMemberFilter,
  teamMemberList,
  teamMemberShowAllButtonRemove,
  teamNationalitiesMap,
  toggleGroup,
  toggleBrandGuideNav,
  twitterFeed,
  transcriptActiveChapterIndicator,
  transcriptActiveChapterObserver,
  transcriptScrollTo,
  transcriptToggle,
  transcriptToggleEnhancer,
  trashTrackerAutocomplete,
  trashTrackerArcticMessage,
  trashTrackerErrorMessage,
  trashTrackerMap,
  trashTrackerLocationForm,
  trashTrackerShowWhileLoading,
  trashTrackerProbabilityInfo,
  trashTrackerTravelDistanceInfo,
  trashTrackerLocationTitle,
  trashTrackerMobileToggle,
  trashTrackerSecondaryButtons,
  twinElementHover,
  urlTriggeredNewsletter,
  wysiwygUpdateLayout,
  zoomableImage,
  zoomableImageFinder,
};

export const HANDLERS = {
  acceptAllCookies,
  boardMemberList,
  classToggler,
  closePopupHandler,
  emailSuggestionsUse,
  emailSuggestionsReject,
  faqItem,
  filterNav,
  hideInfoBox,
  imageOverlay,
  infoTabs,
  jobFormToggle,
  lazyAudioEmbedClose,
  lazyAudioEmbedPlay,
  lazyVideoEmbedClose,
  lazyVideoEmbedPlay,
  listExpander,
  mapMetaHandler,
  mapboxMetricsToggler,
  mediaTabs,
  mediaGalleryNavigationItem,
  milestonesDown,
  milestonesUp,
  milestonesSelect,
  nativeShareButton,
  openPopupHandler,
  removeElement,
  selectCookiePreference,
  siteNavToggler,
  socialUpdatesSliderClose,
  scrollTo,
  showAllTeamMembers,
  showOverview,
  siteBannerClose,
  socialShareButton,
  sourcesMapPopupButton,
  stickyTocToggle,
  siteSearchFilter,
  siteSearchPaginate,
  siteSearchToggle,
  sunglassesStoriesSliderHandler,
  toggleGroupHandler,
  teamMembersShowAll,
  transcriptScrollToHandler,
  trashTrackerResetForm,
  videoOverlay,
  wordDefinition,
  zoomToLatestSurvey,
};

const main = () => {
  executeOnReady();
  enhance(document.documentElement, ENHANCERS);
  handle(document.documentElement, HANDLERS);
};

onDomReady(main);
