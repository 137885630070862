/* eslint-disable no-unused-vars */

import Flickity from "flickity";

export const enhancer = (el) => {
  const flkty = new Flickity(el, {
    autoPlay: 10000,
    wrapAround: false,
    pageDots: false,
    prevNextButtons: true,
    resize: true,
    cellAlign: "left",
    arrowShape:
      "M-4.28051e-06 48.9629L52.2464 100.281L58 94.4232L11.7173 48.9629L55.6027 5.85721L49.8491 -0.000483923L-4.28051e-06 48.9629Z",
    setGallerySize: false,
    contain: true,
  });
};
