import { publish, subscribe } from "./observer";
import { matchesBreakpoint } from "./responsive";
import { throttle } from "./util";

const handleTranscriptToggleClick = (element) => (event) => {
  event.preventDefault();
  publish("transcript_toggle", { id: element.getAttribute("data-target-id") });
};
const handleTranscriptToggle =
  (element) =>
  ({ id, force }) => {
    if (element.getAttribute("data-target-id") !== id) return;

    // Force the element to open.
    if (force) {
      element.classList.add("is-active");
      element.setAttribute("aria-expanded", true);
      return;
    }

    // Toggle attributes and classes.
    element.classList.toggle("is-active");
    element.setAttribute(
      "aria-expanded",
      element.classList.contains("is-active"),
    );
  };
export const transcriptToggleEnhancer = (element) => {
  element.addEventListener("click", handleTranscriptToggleClick(element));
  subscribe("transcript_toggle", handleTranscriptToggle(element));
};

export const scrollToHandler = (element, event) => {
  event.preventDefault();
  publish("transcript_scroll_to", element.getAttribute("href"));
  publish("transcript_toggle", {
    id: "transcript-transcript",
    force: true,
  });
};

/**
 * Scroll to the correct section within the container.
 */
const handleScrollTo = (container) => (href) => {
  const target = container.querySelector(href);
  if (target) {
    container.scrollTop = target.offsetTop - container.offsetTop;
  }
};

export const scrollToEnhancer = (element) => {
  subscribe("transcript_scroll_to", handleScrollTo(element));
};

/**
 * Enable or disable links in the container.
 * @param {HTMLElement} container
 * @param {0 | -1} state
 */
const manageLinksInContainer = (container, state) => {
  container.querySelectorAll("a").forEach((link) => {
    link.setAttribute("tabindex", state);
  });
};

const handleToggle =
  (container) =>
  ({ id, force }) => {
    if (container.getAttribute("data-id") !== id) return;

    if (force) {
      container.classList.add("is-active");
      manageLinksInContainer(container, 0);
      return;
    }
    container.classList.toggle("is-active");
    manageLinksInContainer(
      container,
      container.classList.contains("is-active") ? 0 : -1,
    );
  };

export const toggleEnhancer = (element) => {
  subscribe("transcript_toggle", handleToggle(element));

  // Disable links when the screen is small.
  if (!matchesBreakpoint("medium")) {
    manageLinksInContainer(element, -1);
  }

  // Force containers to open when the screen is resized to a minimum of medium.
  const handleResize = () => {
    if (window.innerWidth >= 720 && !element.classList.contains("is-active")) {
      publish("transcript_toggle", {
        id: "transcript-transcript",
        force: true,
      });
      publish("transcript_toggle", {
        id: "transcript-chapters",
        force: true,
      });
    }
  };

  // Manage links when the screen is resized.
  window.addEventListener("resize", throttle(handleResize, 100));
};

/**
 * Notify the active chapter when it is in view.
 */
export const transcriptActiveChapterObserverEnhancer = (element) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = entry.target.getAttribute("id");
          publish("transcript_active_chapter", id);
        }
      });
    },
    { threshold: 0.1, rootMargin: "0px 0px 0% 0px" },
  );

  observer.observe(element);
};
/**
 * Highlight the active chapter.
 */
export const transcriptActiveChapterIndicatorEnhancer = (element) => {
  subscribe("transcript_active_chapter", (id) => {
    element.setAttribute(
      "aria-current",
      element.getAttribute("data-id") === id,
    );
  });
};
