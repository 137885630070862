// Utils.
import { waitForGlobal } from "../util";

// MapBox.
import { createDefaultMap } from "../mapbox/default-mapbox-map";

// Trash tracker.
import { initializeMapForData } from "./layers";

const TRASH_TRACKER_MAP_STYLE =
  "mapbox://styles/theoceancleanup/ckkdvcfh00o3s17p57avfeuvk";

/**
 * Initializes the map.
 * Also responsible for getting and sending the data.
 */
const initializeMap = (token, labels) => (mapboxgl) => {
  // Setup map.
  const mapBoxMap = createDefaultMap(
    {
      mapboxgl,
      token,
      controlPosition: "top-left",
    },
    { style: TRASH_TRACKER_MAP_STYLE },
  );

  // Initialize map.
  mapBoxMap.on("load", async () => {
    // Initialize map.
    initializeMapForData({
      map: mapBoxMap,
      data: [],
      mapboxgl,
      labels,
    });
  });
};

export const enhancer = (element) => {
  const token = element.getAttribute("data-mapbox-token");
  const labels = {
    polarPopupText: element.getAttribute("data-polar-popup-text"),
    distancePopupText: element.getAttribute("data-distance-popup-text"),
  };

  // Wait until the external `mapboxgl` is loaded.
  waitForGlobal("mapboxgl", 100).then(initializeMap(token, labels));
};
