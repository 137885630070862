import SweetScroll from "sweet-scroll";

export const scrollTo = (
  target,
  clickTarget,
  container,
  customDuration,
  enableHorizontalScroll,
  customOffset,
) => {
  const fixedHeader = document.querySelector(".js-fixed-header");

  const options = {
    duration: 1200,
    easing: "easeInOutCubic",
    offset:
      customOffset || (fixedHeader ? -(fixedHeader.offsetHeight + 20) : 0),
    complete: () => {
      if (clickTarget) {
        clickTarget.click();
      }
    },
  };

  if (enableHorizontalScroll) {
    options.horizontal = true;
    options.vertical = false;
  }
  if (customDuration) {
    options.duration = customDuration;
  }

  const sweetScroll = container
    ? new SweetScroll(options, container)
    : new SweetScroll(options);

  if (typeof target === "string") {
    sweetScroll.to(target);
  } else {
    sweetScroll.toElement(target);
  }
};

export const handler = (el, e) => {
  e.preventDefault();

  const target = el.hash || el.getAttribute("href");
  const clickTarget = document.querySelector(
    el.getAttribute("data-scroll-to-handle"),
  );

  scrollTo(
    target,
    clickTarget,
    document.querySelector(
      el.getAttribute("data-scroll-to-container-selector"),
    ),
    el.getAttribute("data-scroll-to-custom-duration"),
    el.getAttribute("data-scroll-to-horizontal"),
    el.getAttribute("data-scroll-to-custom-offset"),
  );
};
