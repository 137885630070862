import { map } from "./util";
import { matchesBreakpoint } from "./responsive";

const HTML_ACTIVE_CLASS = "has-active-site-nav";

const NAV_SELECTOR = ".js-site-nav";
const TOGGLE_BUTTON_SELECTOR = ".js-site-nav-toggle";
const TOGGLE_BUTTON_LABEL_SELECTOR = ".js-site-nav-toggle-label";
const SUBMENU_TOGGLE_SELECTOR = ".js-submenu-toggle";
const SUBMENU_BACK_SELECTOR = ".js-submenu-back";

const SiteNavSubmenus = (nav) => {
  const toggles = nav.querySelectorAll(SUBMENU_TOGGLE_SELECTOR);

  const alignSubmenu = (submenu) => {
    const screenWidth = document.body.offsetWidth;
    if (submenu.getBoundingClientRect().right > screenWidth) {
      submenu.classList.add("is-right-aligned");
    }
  };

  const toggleSubmenu = ({ toggle, shouldShow }) => {
    const submenu = document.getElementById(
      toggle.getAttribute("aria-controls"),
    );

    // Enable/disable the submenu links.
    const submenuLinks = Array.from(submenu.querySelectorAll("a"));
    submenuLinks.forEach((link) => {
      link.setAttribute("tabindex", shouldShow ? "0" : "-1");
    });

    submenu.setAttribute("data-hide-submenu", !shouldShow);
    toggle.setAttribute("aria-expanded", shouldShow);
    toggle.parentNode.setAttribute("data-expanded", shouldShow);
    if (shouldShow) {
      alignSubmenu(submenu);
    }
  };

  const toggleActiveState = ({ toggle, shouldShow }) => {
    shouldShow =
      typeof shouldShow !== "undefined"
        ? shouldShow
        : toggle.getAttribute("aria-expanded") !== "true";

    const toggleList = Array.from(toggles);

    const nonSelectedToggles = toggleList.filter(
      (nonSelectedToggle) => nonSelectedToggle !== toggle,
    );

    toggleSubmenu({ toggle, shouldShow });

    const toggleType = toggle.getAttribute("data-toggle-type");

    if (toggleType === "primary") {
      toggle.parentNode.lastElementChild.addEventListener(
        "transitionend",
        (e) => {
          if (toggle.getAttribute("aria-expanded") === "true") {
            map(nonSelectedToggles, (nonSelectedToggle) =>
              toggleSubmenu({ toggle: nonSelectedToggle, shouldShow: false }),
            );
          }
        },
      );
    } else {
      map(toggles, (nonSelectedToggle) =>
        toggleSubmenu({ toggle: nonSelectedToggle, shouldShow: false }),
      );
      toggleSubmenu({ toggle, shouldShow });
    }
  };

  const toggleClickHandler = (toggle) => (e) => {
    const isExpanded = e.target.getAttribute("aria-expanded");

    if (e.detail === 0 && isExpanded === "false") {
      e.preventDefault();
    }

    if (!matchesBreakpoint("mediumLarge") && isExpanded === "false") {
      e.preventDefault();
    }

    toggleActiveState({
      toggle,
      shouldShow: matchesBreakpoint("mediumLarge") ? true : undefined,
    });
  };

  const toggleMouseEnterHandler = (toggle) => (e) => {
    if (matchesBreakpoint("mediumLarge")) {
      toggleActiveState({
        toggle,
        shouldShow: true,
      });
    }
  };

  const toggleMouseLeaveHandler = (toggle) => (e) => {
    if (matchesBreakpoint("mediumLarge")) {
      toggleActiveState({
        toggle,
        shouldShow: false,
      });
    }
  };

  // Check if `child` is a descendant of `parent`
  const isDescendantOrChild = (parent, child) => {
    let node = child.parentNode;
    while (node) {
      if (node === parent) {
        return true;
      }

      // Traverse up to the parent
      node = node.parentNode;
    }

    // Go up until the root but couldn't find the `parent`
    return false;
  };

  return {
    init() {
      map(toggles, (toggle) => {
        toggle.addEventListener("click", toggleClickHandler(toggle));

        const toggleContainer = toggle.parentNode;
        toggleContainer.addEventListener(
          "mouseenter",
          toggleMouseEnterHandler(toggle),
          false,
        );

        toggleContainer.addEventListener(
          "mouseleave",
          toggleMouseLeaveHandler(toggle),
          false,
        );

        // close the menu on out of bounds clicks
        window.addEventListener("click", (e) => {
          if (matchesBreakpoint("mediumLarge")) {
            const isChild = isDescendantOrChild(toggle.parentNode, e.target);
            if (
              toggle.getAttribute("aria-expanded") === "true" &&
              isChild === false
            ) {
              toggleActiveState({
                toggle,
                shouldShow: false,
              });
            }
          }
        });

        toggleActiveState({
          toggle,
          shouldShow: false,
        });
      });
    },
    toggleSubmenu,
  };
};

const toggleSiteNav = ({ shouldHide }) => {
  const nav = document.querySelector(NAV_SELECTOR);
  const button = document.querySelector(TOGGLE_BUTTON_SELECTOR);
  const label = button.querySelector(TOGGLE_BUTTON_LABEL_SELECTOR);
  const submenus = SiteNavSubmenus(nav);
  const toggles = nav.querySelectorAll(SUBMENU_TOGGLE_SELECTOR);
  const backButtons = nav.querySelectorAll(SUBMENU_BACK_SELECTOR);

  nav.setAttribute("data-expanded", !shouldHide);
  button.setAttribute("aria-expanded", !shouldHide);
  label.textContent = shouldHide ? "Menu" : "Close";
  map(toggles, (t) => submenus.toggleSubmenu({ toggle: t, shouldShow: false }));

  // add events to the backbuttons
  map(backButtons, (backButton) =>
    backButton.addEventListener("click", (e) =>
      map(toggles, (t) =>
        submenus.toggleSubmenu({ toggle: t, shouldShow: false }),
      ),
    ),
  );

  document.documentElement.classList.toggle(HTML_ACTIVE_CLASS, !shouldHide);
};

export const toggleHandler = (button, e) => {
  e.preventDefault();
  toggleSiteNav({
    shouldHide: button.getAttribute("aria-expanded") === "true",
  });
};

export const enhancer = (nav) => {
  const submenus = SiteNavSubmenus(nav);
  submenus.init();
};
