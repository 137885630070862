/* eslint-disable no-new */

import Flickity from "flickity";

export const enhancer = (container) => {
  const slider = container.querySelector(".js-slider");

  new Flickity(slider, {
    autoPlay: false,
    cellAlign: "left",
    contain: true,
    freeScroll: false,
    pageDots: false,
    prevNextButtons: true,
    arrowShape: "",
    groupCells: true,
  });
};
