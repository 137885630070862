import { subscribe } from "../observer";
import { sunglassesStoriesReceivedData } from "../observer-subjects";
import { getStoriesToAdd, getPrioId } from "./sunglasses-utils";

/**
 * Called by the observer.
 *
 * Add stories to the DOM based on the received data.
 */
const handleDataUpdate =
  (element, template, maxResults, prioId) =>
  ({ stories }) => {
    // Empty current DOM
    element.innerHTML = "";

    const storiesToAdd = getStoriesToAdd(stories, maxResults, prioId);

    // Add stories to the DOM
    storiesToAdd.forEach((story) => {
      // Clone template to be able to re-use the HTML.
      const clone = template.content.cloneNode(true);

      // Get template elements and fill with correct content.
      const aliasElement = clone.querySelector('[data-element="alias"]');
      const storyElement = clone.querySelector('[data-element="story"]');

      if (aliasElement) {
        aliasElement.innerHTML = story.attributes.alias;
      }
      if (storyElement) {
        storyElement.innerHTML = story.attributes.story;
      }

      // Add the clone to the DOM.
      element.appendChild(clone);
    });
  };

/**
 * Update the DOM based on th data.
 *
 * This is a generic function. Any attributes should be set in the template.
 */
export const enhancer = (element) => {
  if ("content" in document.createElement("template")) {
    const template = element.querySelector("template");
    const maxResults = Number(element.getAttribute("data-max-results"));

    subscribe(
      sunglassesStoriesReceivedData,
      handleDataUpdate(element, template, maxResults, getPrioId()),
    );
  }
};
