import { create as createShareButton } from "./social-share-button";
import { create as createNativeShareButton } from "./native-share-button";
import { matchesBreakpoint } from "./responsive";

const SHARE_OPTIONS = ["facebook", "twitter", "linkedin", "mail"];

const LINK_ATTRIBUTE = "data-share-link";
const TITLE_ATTRIBUTE = "data-share-title";
const DESCRIPTION_ATTRIBUTE = "data-share-description";
const BUTTON_LABEL_ATTRIBUTE = "data-share-button-label";

/**
 * Generates a native share button when supported, or falls backs to regular
 * share buttons for social media and e-mail.
 */
export const ShareButtons = ({ buttonLabel, ...shareOptions }) => {
  /**
   * Detect native Share API capabilities.
   * Note: Chrome desktop also matches all these checks, although it throws
   * a `DOMException` when triggered.
   */
  const canShareNatively = () => {
    if (matchesBreakpoint("small")) {
      return false;
    }
    if (typeof navigator.share !== "function") {
      return false;
    }
    const options = { title: "foo", text: "bar", url: "https://google.com" };
    return navigator.canShare && navigator.canShare(options);
  };

  /**
   * Create native or regular share buttons.
   * Returns an array with buttons.
   */
  const createButtons = () => {
    if (canShareNatively()) {
      return [
        createNativeShareButton({
          ...shareOptions,
          label: `<span></span>${buttonLabel}`,
        }),
      ];
    }
    return SHARE_OPTIONS.map((type) =>
      createShareButton({ type, ...shareOptions }),
    );
  };

  return {
    get: createButtons,
    render: () =>
      createButtons().reduce((acc, button) => `${acc} ${button.outerHTML}`, ""),
    isNative: canShareNatively,
  };
};

export const enhancer = (container) => {
  const shareButtons = new ShareButtons({
    link: container.getAttribute(LINK_ATTRIBUTE),
    title: container.getAttribute(TITLE_ATTRIBUTE),
    description: container.getAttribute(DESCRIPTION_ATTRIBUTE),
    buttonLabel: container.getAttribute(BUTTON_LABEL_ATTRIBUTE),
  });
  container.innerHTML = shareButtons.render();
  container.setAttribute(
    "data-type",
    shareButtons.isNative() ? "native" : "regular",
  );
};
