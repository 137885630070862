import { trackEvent } from "./gtm-event";

export const DATA_ATTR_TARGET = "data-target";
export const DATA_ATTR_CLASS = "data-target-class";
const DATA_CALLBACK = "data-callback";

const triggerIsValid = (item) =>
  item.getAttribute(DATA_ATTR_TARGET) && item.getAttribute(DATA_ATTR_CLASS);

const executeCallback = (fn, elm) => {
  if (typeof window[fn] !== "function") {
    console.error(`window[${fn}] is not a function`);
    return;
  }
  window[fn](elm);
};

export const handler = (elm, e) => {
  if (e.defaultPrevented) {
    return;
  }
  e.preventDefault();
  if (!triggerIsValid(elm)) {
    return;
  }
  const target = document.querySelector(elm.getAttribute(DATA_ATTR_TARGET));
  if (!target) {
    return;
  }

  target.classList.toggle(elm.getAttribute(DATA_ATTR_CLASS));
  if (elm.hasAttribute(DATA_CALLBACK)) {
    executeCallback(elm.getAttribute(DATA_CALLBACK), elm);
  }

  if (elm.getAttribute("data-track-event")) {
    trackEvent({
      type: elm.getAttribute("data-event-type"),
      category: elm.getAttribute("data-event-cat"),
      action: elm.getAttribute("data-event-action"),
      label: elm.getAttribute(
        target.classList.contains(elm.getAttribute(DATA_ATTR_CLASS))
          ? "data-event-label-on"
          : "data-event-label-off",
      ),
      value: elm.getAttribute("data-event-value"),
    });
  }
};
