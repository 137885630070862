import { throttle } from "@grrr/utils";
import { enhance } from "@grrr/hansel";
import { closest } from "./util";

import { enhancer as toggleBrandGuideNav } from "./toggle-brand-guide-nav";

import { register as registerScrollListener } from "./scroll-listener";

// IO visibility settings
const IO_THRESHOLD = [0, 0.1, 0.25, 0.5, 0.75, 0.9, 1];

const getAnchorHash = (anchor) =>
  anchor.hash || anchor.href.substr(anchor.href.indexOf("#"));

const updateNav = ({ toc, nav, items }) => {
  const current = items.reduce(
    (acc, item) => {
      return item.visibility && item.visibility > acc.visibility ? item : acc;
    },
    { visibility: 0 },
  );

  items.map((item) => {
    item.anchor.setAttribute("aria-current", current.anchor === item.anchor);

    const parentItem = closest(
      item.anchor.parentNode,
      `.js-brandguide-navigation-item`,
    );
    if (!parentItem) {
      return;
    }
    const parentAnchor = parentItem.querySelector("a");

    // If item is active, set parent to active as well
    if (current.anchor === item.anchor) {
      parentAnchor.setAttribute("aria-current", true);
    }
    parentAnchor.setAttribute(
      "aria-expanded",
      parentAnchor.getAttribute("aria-current"),
    );
  });
};

const trackWithObserver = ({ toc, nav, items }) => {
  const observerCallback = (entries, observer) => {
    entries.map((entry) => {
      const index = items.findIndex((item) => item.section === entry.target);
      items[index] = { ...items[index], visibility: entry.intersectionRatio };
    });
    updateNav({ toc, nav, items });
  };
  const observer = new IntersectionObserver(observerCallback, {
    threshold: IO_THRESHOLD,
  });

  items.map((item) => {
    if (item.section) observer.observe(item.section);
  });
  observer.observe(toc);
};

const attachReadIndicator = (nav) => {
  const readIndicator = nav.querySelector("progress");
  const scrollHandler = (e) => {
    const pageLength = document.body.scrollHeight - window.innerHeight;
    const percentage = (window.pageYOffset / pageLength) * 100; // length to percentage
    readIndicator.value = percentage;
  };
  registerScrollListener("readIndicatorListener", throttle(scrollHandler, 20));
};

const createExpandButton = () => {
  const button = document.createElement("button");
  button.innerHTML = '<span class="sr-only">Expand menu</span>';
  button.classList.add("brandguide-navigation__toggle-button");
  button.setAttribute("data-enhancer", "toggleBrandGuideNav");
  button.setAttribute("aria-expanded", "false");

  return button;
};

export const enhancer = (el) => {
  const nav = el.querySelector(".js-brandguide-navigation");

  // Attach observer.
  const items = [...nav.querySelectorAll("li a")].map((anchor) => {
    const section = document.querySelector(getAnchorHash(anchor));
    return { anchor, section };
  });
  trackWithObserver({ toc: el, nav, items });
  attachReadIndicator(nav);

  const customButton = createExpandButton(el);
  nav.appendChild(customButton);
  enhance(customButton, { toggleBrandGuideNav });
};
