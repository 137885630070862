import { publish, subscribe } from "../observer";
import {
  citizenScienceSideBarShowOverview,
  citizenScienceSideBarShowSingle,
} from "../observer-subjects";

const show = (element) => {
  element.removeAttribute("aria-hidden");
};
const hide = (element) => {
  element.setAttribute("aria-hidden", "true");
};

/**
 * Called by observer.
 * Show the cluster overview section of the container.
 */
const handleShowOverview = (overview, single, container) => (data) => {
  show(overview);
  hide(single);

  // Set data attr so the cluster overview section is set visible in CSS.
  container.setAttribute("data-state", "overview");
};

/**
 * Called by observer.
 * Show the single survey section of the container.
 */
const handleShowSingle = (overview, single, container) => (data) => {
  hide(overview);
  show(single);

  // Set data attr so the single survey section is set visible in CSS.
  container.setAttribute("data-state", "single");
};

/**
 * Subscribe to observers.
 */
export const enhancer = (container) => {
  const overview = container.querySelector(".js-overview");
  const single = container.querySelector(".js-single");

  subscribe(
    citizenScienceSideBarShowOverview,
    handleShowOverview(overview, single, container),
  );
  subscribe(
    citizenScienceSideBarShowSingle,
    handleShowSingle(overview, single, container),
  );
};

/**
 * Show overview through observer.
 */
export const handler = () => {
  publish(citizenScienceSideBarShowOverview);
};
