import { debounce } from "./util";

const onScroll = (event, element) => {
  if (event.target.scrollLeft > 0) {
    element.parentElement.setAttribute("data-scroll-active", true);
  } else {
    element.parentElement.setAttribute("data-scroll-active", false);
  }
};

export const enhancer = (element) => {
  element.addEventListener(
    "scroll",
    debounce((event) => onScroll(event, element), 100),
  );
};
