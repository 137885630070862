import { subscribe } from "../observer";
import {
  trashTrackerIsDoneDrawing,
  trashTrackerResetData,
  trashTrackerUpdateData,
} from "../observer-subjects";

let reachesArctic = false;

/**
 * Called by observer.
 * Update contents of element.
 */
const handleUpdateData =
  (distanceElement) =>
  ({ travelledDistance, isPolarTrajectory }) => {
    distanceElement.innerHTML = travelledDistance;
    reachesArctic = isPolarTrajectory;
  };

/**
 * Called by observer.
 * Show element.
 */
const handleIsDoneDrawing = (containerElement) => () => {
  // only show when the particle didn't reach the arctic.
  if (!reachesArctic) {
    containerElement.setAttribute("data-show", "true");
  }
};

/**
 * Called by observer.
 * Hide element on reset.
 */
const handleReset = (containerElement) => () => {
  containerElement.setAttribute("data-show", "false");
  reachesArctic = false;
};

export const enhancer = (containerElement) => {
  const distanceElement = containerElement.querySelector(".js-travel-distance");

  subscribe(trashTrackerUpdateData, handleUpdateData(distanceElement));
  subscribe(trashTrackerIsDoneDrawing, handleIsDoneDrawing(containerElement));
  subscribe(trashTrackerResetData, handleReset(containerElement));
};
