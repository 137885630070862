export const handler = (el) => {
  el.setAttribute("data-hide-on-mobile", true);

  const teamMemberList = Array.from(
    el.parentElement.firstElementChild.children,
  );

  teamMemberList.forEach((teamMember) => {
    if (teamMember.getAttribute("data-hide-on-mobile") === "true") {
      teamMember.setAttribute("data-hide-on-mobile", false);
    }
  });
};
