import Fuse from "fuse.js";
import Mark from "mark.js";
import { debounce, htmlToElement, dedup } from "./util";

const FAQ_TITLE_NODE = "dt";
const FAQ_BODY_NODE = "dd";

const FUSE_OPTIONS = {
  shouldSort: true,
  includeMatches: true,
  tokenize: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["title", "body"],
};

const SEARCHFORM_TEMPLATE = `
  <div class="faq-search">
    <form role="search">
      <label for="faq-search">Search FAQ:</label>
      <input type="search" name="q" aria-label="Search term used to search the FAQ" id="faq-search" placeholder="Search in FAQ...">
    </form>
  </div>
`;

/**
 * Find the first matching node, based on item id.
 */
const getQuestionById = (nodes, id) =>
  nodes.find((item) => item.getAttribute("data-item-id") === id);

/**
 * Produce a highlighted copy of the given node.
 */
const highlightQuery = (query, node) => {
  const copy = node.cloneNode(true);
  const mark = new Mark(copy);
  mark.mark(query);
  return copy;
};

/**
 * Create search results template.
 */
const resultsTemplate = ({ query, nodes, results }) => `
<section class="faq-block faq-block--search js-search-results" aria-live="polite">
  <div class="faq-block__inner">
    <h2 class="block-title block-title--left block-title--tiny block-title--primary">Search for <strong>'${query}'</strong></h2>
    <div class="faq-block__listing">
      ${
        results.length
          ? `<dl class="faq-list">
          ${results
            .map(
              (resultId) =>
                highlightQuery(query, getQuestionById(nodes, resultId))
                  .outerHTML,
            )
            .join("\n")}
        </dl>`
          : `<div class="faq-block__notice"><p>No search results found.</p></div>`
      }
    </div>
  </div>
</section>
`;

/**
 * Render search results.
 */
const renderResults = ({ container, nodes, query, results }) => {
  // Toggle visibility of existing lists, based on whether a search was performed.
  const existingLists = container.querySelectorAll(".js-faq-block");
  existingLists.forEach((list) => list.setAttribute("aria-hidden", !!query));

  const existingResults = container.querySelector(".js-search-results");
  if (existingResults) {
    container.removeChild(existingResults);
  }
  if (!query) {
    return;
  }
  const resultsContainer = htmlToElement(
    resultsTemplate({ query, nodes, results }),
  );
  container.insertBefore(
    resultsContainer,
    container.firstChild.nextElementSibling,
  );
};

/**
 * Perform the search.
 */
const performSearch = (data, query) => {
  const fuse = new Fuse(data, FUSE_OPTIONS);
  const results = fuse.search(query);
  return dedup(results.map((result) => result.item.id));
};

/**
 * Respond to keyup events.
 */
const keyupListener = (context) => (e) => {
  context.query = e.target.value;
  context.results = context.query
    ? performSearch(context.searchData, context.query)
    : [];
  renderResults(context);
};

export const enhancer = (el) => {
  const items = [...el.querySelectorAll(".js-question-wrapper")];
  if (!items.length) {
    return;
  }

  const context = {
    container: el,
    nodes: items,
    searchData: items.map((item) => ({
      id: item.getAttribute("data-item-id"),
      title: item.querySelector(FAQ_TITLE_NODE).textContent.trim(),
      body: item.querySelector(FAQ_BODY_NODE).textContent.trim(),
    })),
  };
  const searchForm = htmlToElement(SEARCHFORM_TEMPLATE);
  searchForm.addEventListener("keyup", debounce(keyupListener(context), 200));
  searchForm.addEventListener("submit", (e) => e.preventDefault());

  el.insertBefore(searchForm, el.firstChild);
};
