/* eslint-disable no-return-assign */

import { closest, parseJson } from "@grrr/utils";

const BUTTON_SELECTOR = ".js-button";
const TOGGLE_ATTRIBUTE = "data-toggle";

/**
 * Handle input conditionals by processing conditions specified as JSON in the template.
 * There can be multiple conditions per item, and they're usually applied to a container
 * wrapping one or more inputs.
 *
 * All possible condition properties:
 *
 * - el: node (required) // the node the conditional rule depends on
 * - values: array (required) // array of values which can be allowed or rejected
 * - rejectValues: bool (optional) // when values should be rejected instead of accepted
 * - optionalOnly: bool (optional) // when the rule is only for toggling an item optional
 * - disableForm: bool (optional) // when the rule indicates this is the final step in the form
 * - ignoreVisibility: bool (optional) // validate even when the dependant node is invisible
 */
const ContactFormConditionals = (form) => {
  const buttonEl = form.querySelector(BUTTON_SELECTOR);

  const isVisible = closest(
    (el) => !(el.getAttribute("aria-hidden") === "true"),
  );
  const isSelect = (input) => input.tagName.toLowerCase() === "select";

  /**
   * Get value of input.
   */
  const getValue = (input) => {
    return isSelect(input)
      ? input.options[input.selectedIndex].value
      : input.value;
  };

  /**
   * Reset value of input.
   */
  const resetValue = (input) => {
    if (isSelect(input)) {
      input.selectedIndex = 0;
    } else {
      input.value = "";
    }
  };

  /**
   * Set the state for the conditional item, including its inputs.
   *
   * - Hide/show the container.
   * - Make child inputs disabled when hidden (to disable form validation).
   * - Reset child `select`s when hidden.
   * - Make a field optional when eligible.
   */
  const setItemState = ({ el, matches }) => {
    const inputs = el.querySelectorAll("input, select, textarea");
    const selects = el.querySelectorAll("select");
    const labels = el.querySelectorAll("label");

    // Hide the container.
    el.setAttribute("aria-hidden", !matches.all);

    // Toggle inputs, since even if they're hidden, the browser tries
    // to validate them.
    [...inputs].forEach((input) => (input.disabled = !matches.all));

    // Reset select values when not all conditions are matched.
    // Note: we're leaving other input tyeps intact for now, to prevent
    // accidental deletion of data (e.g. message field).
    if (!matches.all) {
      [...selects].forEach(resetValue);
    }

    // Toggle optionality, but only if the item has a rule for that.
    if (matches.optionalOnly.enabled) {
      [...inputs].forEach(
        (input) => (input.required = !matches.optionalOnly.match),
      );
      [...labels].forEach((label) =>
        label.classList.toggle("is-required", !matches.optionalOnly.match),
      );
    }
  };

  /**
   * Check if element matches required or rejected values.
   */
  const isConditionMatch = ({
    el,
    values,
    rejectValues,
    optionalOnly,
    ignoreVisibility,
  }) => {
    const currentValue = getValue(el);
    // No match if the tested input is visible but empty. This can be overruled
    // by setting the `ignoreVisibility` flag.
    if (isVisible(el) && !currentValue && !ignoreVisibility) {
      return false;
    }
    const included = values.includes(currentValue);
    return rejectValues ? !included : included;
  };

  /**
   * Get an object with the conditional item and all its matches per type.
   */
  const getResultForItem = (item) => {
    const conditions = item.conditions.map((condition) => ({
      ...condition,
      match: isConditionMatch(condition),
    }));
    return {
      el: item.el,
      matches: {
        all: conditions.every((entry) =>
          entry.optionalOnly ? true : entry.match,
        ),
        disableForm: conditions.some(
          (entry) => entry.disableForm && entry.match,
        ),
        optionalOnly: {
          enabled: conditions.some((entry) => entry.optionalOnly),
          match: conditions.some((entry) => entry.optionalOnly && entry.match),
        },
      },
    };
  };

  /**
   * Handle changes to form inputs.
   *
   * Note: the state is set per item while fetching its result. If this
   * were done separately, it would have to run twice, since the result depends
   * on the state of other (earlier) items. This setup works because all items
   * depend on states/decisions earlier in the DOM.
   */
  const changeHandler = (items) => (e) => {
    const results = items.map((item) => {
      const result = getResultForItem(item);
      setItemState(result);
      return result;
    });

    // Set the submit button state based on matched 'disableForm' conditions.
    buttonEl.disabled = results.some((result) => result.matches.disableForm);
  };

  /**
   * Create an object for each toggle item.
   * Example structure:
   *
   * {
   *    el: node // the conditional node which itself will be toggled
   *    conditions: // see all possible conditions at the top of this module
   * }
   */
  const createToggleItem = (el) => {
    const config = parseJson(el.getAttribute(TOGGLE_ATTRIBUTE));
    return {
      el,
      conditions: config.map((entry) => ({
        ...entry,
        el: form.querySelector(`[name="${entry.el}"]`),
      })),
    };
  };

  return {
    init() {
      const toggleElements = form.querySelectorAll(`[${TOGGLE_ATTRIBUTE}]`);
      const toggleItems = [...toggleElements].map(createToggleItem);
      form.addEventListener("change", changeHandler(toggleItems));
    },
  };
};

export const enhancer = (form) => {
  const conditionals = ContactFormConditionals(form);
  conditionals.init();
};
