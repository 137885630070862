import { publish, subscribe } from "./observer";
import { openPopup, newsletterFormIsMounted } from "./observer-subjects";

/**
 * Show newsletter popup if #emailpopup is in the URL
 */
export const enhancer = (el) => {
  const popupId = el.getAttribute("data-popup-id");
  // When you share an URL from Instagram it is encoded, so we need to decode it to get the hash
  const decodedUrl = decodeURIComponent(window.location.href);
  const { hash } = new URL(decodedUrl);

  if (hash.includes("#emailpopup")) {
    el.setAttribute("data-show", "true");
    // Open popup once the newsletter subscribe field/form is mounted.
    // Otherwise the subscribe field will mount mid fade-in animation which looks glitchy.
    subscribe(newsletterFormIsMounted, () => {
      publish(openPopup, popupId);
    });
  }
};
