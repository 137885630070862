/* eslint-disable padded-blocks */

import { MapboxMap } from "./system-map-mapbox";
import { getRelativeTimeLabel, map } from "./util";

const TAB_SELECTOR = ".js-tab";
const PANEL_SELECTOR = ".js-panel";
const MARKER_SELECTOR = ".js-marker";
const LIVE_MARKER_SELECTOR = ".js-live-marker";
const UPDATED_AT_SELECTOR = ".js-updated-at";

const SystemMap = (container) => {
  const setAttribute = ({ el, action, key, value }) => {
    if (!el) {
      return;
    }
    el[action](key, value);
  };

  const resetActiveTabAndMarker = () => {
    setAttribute({
      el: container.querySelector(`${TAB_SELECTOR}[aria-selected="true"]`),
      action: "removeAttribute",
      key: "aria-selected",
    });
    setAttribute({
      el: container.querySelector(`${PANEL_SELECTOR}[aria-hidden="false"]`),
      action: "setAttribute",
      key: "aria-hidden",
      value: "true",
    });
    setAttribute({
      el: container.querySelector(`${MARKER_SELECTOR}[aria-current="step"]`),
      action: "removeAttribute",
      key: "aria-current",
    });
  };

  const setActiveTabAndMarker = (id) => {
    setAttribute({
      el: container.querySelector(`${TAB_SELECTOR}[id="${id}"]`),
      action: "setAttribute",
      key: "aria-selected",
      value: "true",
    });
    setAttribute({
      el: container.querySelector(`${PANEL_SELECTOR}[aria-labelledby="${id}"]`),
      action: "setAttribute",
      key: "aria-hidden",
      value: "false",
    });
    setAttribute({
      el: container.querySelector(
        `${MARKER_SELECTOR}[aria-labelledby="${id}"]`,
      ),
      action: "setAttribute",
      key: "aria-current",
      value: "step",
    });
  };

  const handleTabClick = (tab) => (e) => {
    e.preventDefault();
    resetActiveTabAndMarker();
    setActiveTabAndMarker(tab.getAttribute("id"));
  };

  const handleMarkerClick = (marker) => (e) => {
    e.preventDefault();
    marker.blur();
    resetActiveTabAndMarker();
    setActiveTabAndMarker(marker.getAttribute("aria-labelledby"));
  };

  const handleClickEvents = () => {
    const tabs = container.querySelectorAll(TAB_SELECTOR);
    map(tabs, (tab) => tab.addEventListener("click", handleTabClick(tab)));

    const markers = container.querySelectorAll(MARKER_SELECTOR);
    map(markers, (marker) =>
      marker.addEventListener("click", handleMarkerClick(marker)),
    );
  };

  const setUpdatedAtLabel = (label) => {
    const wrapper = container.querySelector(UPDATED_AT_SELECTOR);
    wrapper.querySelector("span").textContent = label;
    wrapper.setAttribute("aria-hidden", "false");
  };

  const updateLiveMarkers = (mapModule) => {
    const markers = container.querySelectorAll(LIVE_MARKER_SELECTOR);
    [...markers].forEach((marker) => {
      mapModule.updateLiveMarker(marker).then((dateUTC) => {
        const label = getRelativeTimeLabel(dateUTC);
        if (label) {
          setUpdatedAtLabel(label);
        }
      });
    });
  };

  return {
    init(mapModule) {
      handleClickEvents();
      updateLiveMarkers(mapModule);
    },
  };
};

export const enhancer = (el) => {
  const mapModule = MapboxMap(el);

  mapModule.init().then(() => {
    // Initialize handlers.
    const systemMap = SystemMap(el);
    systemMap.init(mapModule);
  });
};
