export const enhancer = (container) => {
  const triggers = Array.from(
    container.querySelectorAll('[data-element="scroller-trigger"]'),
  );
  let activeTriggerIndex = 0;

  const makeActiveTriggerVisible = () => {
    triggers.forEach((item, index) => {
      if (index === activeTriggerIndex) {
        item.setAttribute("data-is-active", "true");
      } else {
        item.setAttribute("data-is-active", "false");
      }
    });
  };

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeTriggerIndex = Number(entry.target.getAttribute("data-index"));
        makeActiveTriggerVisible();
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, {
    threshold: 0.5,
  });

  triggers.forEach((trigger) => {
    observer.observe(trigger);
  });

  makeActiveTriggerVisible();
};
