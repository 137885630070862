/* eslint-disable prefer-regex-literals */

// General knowledge needed:
// An if block is {% if a %} or {% if a == 'a' %}
// An if statement is a or a == 'a'
// An ifElseBlock is {% if .. %} ... {% endif .. %}

/**
 * Generate list of if/endif blocks.
 */
const getIfElseList = (template) => {
  // Will match any string that starts with {% if and ends with %}
  const ifMatcher = new RegExp(`{% if(.*?)%}`, `g`);
  // Will match any string {% endif %}
  const endIfMatcher = new RegExp(`{% endif(.*?)%}`, `g`);

  // Find all occurrences of {% if ... %} and {% endif %} in the template
  const ifBlockList = template.match(ifMatcher);
  const endIfBlockList = template.match(endIfMatcher);

  return { ifBlockList, endIfBlockList };
};

/**
 * Get the if statement without the Twig tags.
 */
const getIfStatementAsString = (ifBlock) =>
  ifBlock.replace("{% if ", "").replace(" %}", "");

/**
 * Get the endif statement without the Twig tags.
 */
const getEndIfStatementAsString = (endIfBlock) =>
  endIfBlock.replace("{% endif ", "").replace(" %}", "");

/**
 * Remove the optional `not` value from the string.
 */
const removeNot = (statement) => statement.replace("not ", "");

/**
 * Get the if statement form the if block.
 * From {% if a == 'a' %} to a == 'a'
 */
const getIfStatement = (ifBlock, values) => {
  const ifStatementAsString = getIfStatementAsString(ifBlock);

  // Handle if statement wit "not" like {% if not a %}
  if (ifStatementAsString.includes("not")) {
    return !values[ifStatementAsString.replace("not", "").replaceAll(" ", "")];
  }

  // Handle if statement with equal operator like {% if a == 'a' %}
  if (ifStatementAsString.includes("==")) {
    // Split on known operator.
    const before = ifStatementAsString.split(" == ")[0];
    // Remove any quotes from the string.
    const after = ifStatementAsString
      .split(" == ")[1]
      .replaceAll(`'`, ``)
      .replaceAll(`"`, ``);
    // Create a new if statement.

    // Because Twig doesn't validate the type, we also should check the type.
    // eslint-disable-next-line eqeqeq
    return values[before] == after;
  }

  // Handle if statement with single operator like {% if a %}
  return values[ifStatementAsString];
};

/**
 * Remove if/endif tags.
 */
const removeIfElseTags = (template, ifBlock, endIfBlock) =>
  template.slice().replace(ifBlock, "").replace(endIfBlock, "");

/**
 * Get total string including if/endif block.
 */
const getIfElseBlock = (template, ifBlock, endIfBlock) =>
  template.substring(
    template.indexOf(ifBlock),
    template.indexOf(endIfBlock) + endIfBlock.length,
  );

/**
 * Handle if/endif statements in the template.
 * Supported statements
 * {% if item %}{% endif item %}
 * {% if not item %}{% endif item %}
 * {% if item == 'something' %}{% endif item == 'something' %}
 *
 * It also supports nested if statements due to the condition being declared at the endif block.
 *
 * @param {String} template
 * @param {Object} values
 * @returns {String}
 */
const handleIfElse = (template, values) => {
  // Duplicate template.
  let newTemplate = template.slice();
  const { ifBlockList, endIfBlockList } = getIfElseList(newTemplate);

  // Bail out of the recursion if there are no if/else blocks.
  if (!ifBlockList || !endIfBlockList) {
    return newTemplate;
  }

  // Get if block (it's a recursive function, so one at a time).
  const ifBlock = ifBlockList[0];

  // Get the if statement from the if block.
  const ifStatementAsString = getIfStatementAsString(ifBlock);
  // Get the corresponding endif block (the endif block also contains the condition).
  const endIfBlock = endIfBlockList.find(
    (item) =>
      removeNot(getEndIfStatementAsString(item)) ===
      removeNot(ifStatementAsString),
  );

  // Throw error when the corresponding endif block is not found.
  if (!endIfBlock) {
    console.error(
      "Error: No matching endif block found. This is probably an if/else statement within the verbatim block. Be aware that any 'endif' should also contain the check from the if statement.",
    );
  }

  // Get if statement inside the ifBlock.
  const ifStatement = getIfStatement(ifBlock, values);

  // If the if statement is true, remove the if and andif tags.
  if (ifStatement) {
    newTemplate = removeIfElseTags(newTemplate, ifBlock, endIfBlock);

    // Execute recursion.
    return handleIfElse(newTemplate, values);
  }

  // Otherwise, remove the entire if/endif block.
  const ifBlockContents = getIfElseBlock(newTemplate, ifBlock, endIfBlock);
  newTemplate = newTemplate.replace(ifBlockContents, "");

  return handleIfElse(newTemplate, values);
};

/**
 * Treat the given template as a Twig template.
 * Supported Twig features:
 * * {{ variable }}
 * * {% if item %}{% endif %}
 * * {% if item == 'something' %}{% endif %}
 *
 * @param {String} template - The HTML from the Twig template, containing Twig tags.
 * @param {Object} values - All data, used to replace variables in template and handle if statements.
 * @returns
 */
export const toTemplate = (template, values) => {
  const templateWithoutIfElseStatements = handleIfElse(template, values);

  return Object.entries(values).reduce((currentTemplate, [key, value]) => {
    // Copy currentTemplate string
    const newTemplate = currentTemplate;

    // Regex to replace Twig value with value from data (values).
    const matcher = new RegExp(`{{ ${key} }}`, `g`);
    // Replace all occurrences of the Twig value with the value from data.
    return newTemplate.replace(matcher, value);
  }, templateWithoutIfElseStatements);
};
