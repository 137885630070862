import { forEach } from "./util";

const PANE_SELECTOR = ".js-pane";
const TAB_SELECTOR = ".js-tab";

const getPanes = (el) => el.querySelectorAll(PANE_SELECTOR);
const getTabs = (el) => el.querySelectorAll(TAB_SELECTOR);

const getStep = (controls) =>
  parseInt(controls.substr(controls.length - 1), 10);

const select = (wrapper, button) => {
  const controls = button.getAttribute("aria-controls");
  wrapper.setAttribute("data-step", getStep(controls));

  // reset all states
  forEach(getTabs(wrapper), (tab) =>
    tab.setAttribute("aria-selected", "false"),
  );
  forEach(getPanes(wrapper), (pane) =>
    pane.setAttribute("aria-hidden", "true"),
  );

  // select current tab and pane
  wrapper.querySelector(`#${controls}`).setAttribute("aria-hidden", "false");
  button.setAttribute("aria-selected", "true");
  button.blur();
};

export const enhancer = (wrapper) => {
  forEach(getTabs(wrapper), (button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      select(wrapper, button);
    });
  });
};
