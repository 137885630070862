/**
 * Hero slider, as used on the homepage
 */

import SweetScroll from "sweet-scroll";
import { previous } from "./util";

const LIST_ELEMENT = "ul";
const ITEM_ELEMENT = "li";

const HeroSlider = (container) => {
  const list = container.querySelector(LIST_ELEMENT);

  const sweetScroll = Modernizr.toucheventsfixed
    ? undefined
    : new SweetScroll(
        {
          vertical: true,
          horizontal: true,
          duration: 500,
          easing: "easeInOutCubic",
        },
        list.parentNode,
      );

  const transform = (el, value) => {
    el.style.MozTransform = value;
    el.style.msTransform = value;
    el.style.OTransform = value;
    el.style.WebkitTransform = value;
    el.style.transform = value;
  };

  const slide = () => {
    const listWidth = container.getBoundingClientRect().width;
    const items = container.querySelectorAll(ITEM_ELEMENT);
    const itemWidth = items[0].getBoundingClientRect().width;

    if (Modernizr.toucheventsfixed) {
      const scroll = itemWidth;
      sweetScroll.toLeft(scroll);
      return;
    }

    const ratio = (itemWidth / listWidth) * 100;
    const offset = ratio * items.length - 100;

    const translate = `translateZ(0) translateX(-${offset}%)`;
    transform(list, translate);
  };

  const reset = () => {
    if (Modernizr.toucheventsfixed) {
      sweetScroll.toLeft(0);
      return;
    }

    const translate = `translateZ(0) translateX(0)`;
    transform(list, translate);
  };

  const handleMouseEnter = (el) => {
    if (previous(el)) {
      slide();
      return;
    }
    reset();
  };

  return {
    init() {
      const items = [...container.querySelectorAll(ITEM_ELEMENT)];
      if (!items.length) {
        return;
      }

      items.forEach((item) => {
        item.addEventListener("mouseenter", (e) => {
          e.preventDefault();
          handleMouseEnter(item);
        });
      });
    },
  };
};

export const enhancer = (container) => {
  const heroSlider = HeroSlider(container);
  heroSlider.init();
};
