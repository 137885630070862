/* eslint-disable no-return-assign, prefer-destructuring, import/no-cycle, no-nested-ternary */

import URLSearchParams from "@ungap/url-search-params";

export const MARKER_CLICK_EVENT = "marker-click";

// The unique id included in the feature properties.
export const MARKER_ID = "ORIG_FID_1";

// Zoom level for going to the popup level via flyTo.
const POPUP_ZOOM_LEVEL = 5;
// Zoom level for going to the detail level via flyTo (satellite layer is set at 14).
const DETAIL_ZOOM_LEVEL = 14.2;
// Zoom level for allowing popup clicks instead of flyTo.
const DETAIL_ZOOM_CLICK_THRESHOLD = 14;
// Threshold level for going to the detail instead of back to popup.
const DETAIL_ZOOM_GOTO_THRESHOLD = 6.5;

// All layers containing interactive markers.
// Each marker layer must correspond to the name of the layer in MapBox (visible in the left side-bar).
// Do not add the markers from the layer with a blue color!
const MARKER_LAYERS = [
  "emissions 100 copy 2",
  "emissions 100 copy 1",
  "emissions 10k copy 2",
  "emissions 10k copy 1",
  "emissions 1k copy 2",
  "emissions 1k copy 1",
];

export const SourcesMapMapbox = ({ container, mapboxgl, token, popup }) => {
  const currentPopup = {
    id: undefined,
    object: undefined,
  };

  /**
   * Set Mapbox access token.
   */
  mapboxgl.accessToken = token;

  /**
   * Event callbacks.
   */
  const listeners = {};
  const onUpdate = (eventType, fn) => {
    listeners[eventType] = [...(listeners[eventType] || []), fn];
  };
  const dispatchEvent = (eventType) => {
    if (listeners[eventType]) {
      listeners[eventType].forEach((fn) => fn());
    }
  };

  /**
   * Initialize and create the map object.
   */
  const map = new mapboxgl.Map({
    container: "map",
    style:
      "mapbox://styles/theoceancleanup/cl8sbsj4m000l14ocahttu5jg?fresh=true",
    logoPosition: "bottom-left",
    center: [17, 20],
    zoom: 1.4,
    attributionControl: false,
    renderWorldCopies: true,
  }).addControl(
    new mapboxgl.AttributionControl({
      compact: false,
    }),
  );

  /**
   * Helpers to check if layers and sources are loaded.
   */
  const isSourceLoaded = (source) =>
    map.getSource(source) && map.isSourceLoaded(source);
  const isLayerLoaded = (layer) => map.getLayer(layer);
  const allLayersLoaded = (layers) => layers.every(isLayerLoaded);

  /**
   * Remove existing popup and show a new one with given details.
   */
  const showPopup = ({ coordinates, properties }) => {
    if (currentPopup.object) {
      currentPopup.object.remove();
    }
    currentPopup.id = properties[MARKER_ID];
    currentPopup.object = new mapboxgl.Popup({
      maxWidth: "calc(100vw - 30px)",
    })
      .setLngLat(coordinates)
      .setHTML(popup.html(properties))
      .addTo(map);
  };

  /**
   * Handler for marker clicks.
   */
  const goToFeature = (feature) => {
    const currentZoom = map.getZoom();
    const coordinates = feature.geometry.coordinates;
    const properties = feature.properties;

    // Dispatch the `marker-click` event.
    dispatchEvent(MARKER_CLICK_EVENT);

    // When zoomed in to detail level, only show popup and don't flyTo.
    if (currentZoom > DETAIL_ZOOM_CLICK_THRESHOLD) {
      showPopup({ coordinates, properties });
      return;
    }

    // Set new zoom level based on current zoom level.
    // Go to detail or zoom level given the threshold, and go to zoom when
    // the current popup-level on has a popup open (a.k.a. 'zoom further').
    const zoomTo =
      currentZoom >= DETAIL_ZOOM_GOTO_THRESHOLD
        ? DETAIL_ZOOM_LEVEL
        : currentPopup.id === properties[MARKER_ID]
          ? DETAIL_ZOOM_LEVEL
          : POPUP_ZOOM_LEVEL;

    // Show popup if the new zoom level is the popup one.
    if (zoomTo <= POPUP_ZOOM_LEVEL) {
      showPopup({ properties, coordinates });
    }

    // Fly to new coordinates; offset it a bit when a popup will be shown.
    map.flyTo({
      center: [
        coordinates[0],
        coordinates[1] + (zoomTo === POPUP_ZOOM_LEVEL ? -2.5 : 0),
      ],
      speed: 1,
      zoom: zoomTo,
    });

    // We'll leave this here for now, as to better inspect possible data glitches.
    console.info(feature);
  };

  /**
   * Listener for map clicks (to reset marker focus state).
   */
  const mapClickHandler = (e) => {
    if (!allLayersLoaded(MARKER_LAYERS)) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point, {
      layers: MARKER_LAYERS,
    });
    if (features.length) {
      goToFeature(features[0]);
    }
  };

  /**
   * Listener for map mouse events (to set mouse pointer).
   */
  const mapMouseMoveHandler = (e) => {
    if (!allLayersLoaded(MARKER_LAYERS)) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point, {
      layers: MARKER_LAYERS,
    });
    map.getCanvas().style.cursor = features.length ? "pointer" : "";
  };

  /**
   * Attach event listeners to the Mapbox map.
   */
  const attachMapListeners = () => {
    map.on("mousemove", mapMouseMoveHandler);
    map.on("click", mapClickHandler);
  };

  /**
   * Open initial marker when opened via URL.
   */
  const openInitialMarkerFromParam = (id) => {
    if (!id) {
      return;
    }

    // Query all sources for the eligible layers for markers with the given id.
    // The id is not unique across these layers, so we focus on the first one.
    const getAndSetFeature = () => {
      const features = MARKER_LAYERS.reduce((acc, layer) => {
        return [
          ...acc,
          ...map.querySourceFeatures("composite", {
            sourceLayer: map.getLayer(layer).sourceLayer,
            filter: ["==", MARKER_ID, parseInt(id, 10)],
          }),
        ];
      }, []);
      if (!features.length) {
        return;
      }
      goToFeature(features[0]);
    };

    // We'll have to wait until the source and all layers are loaded.
    const isLoaded = () =>
      isSourceLoaded("composite") && allLayersLoaded(MARKER_LAYERS);
    const loadOrWait = () =>
      isLoaded() ? getAndSetFeature() : map.once("render", loadOrWait);

    loadOrWait();
  };

  return {
    init() {
      attachMapListeners();

      // Open initial system if it's specified in the URL.
      const searchParams = new URLSearchParams(window.location.search);
      openInitialMarkerFromParam(searchParams.get("id"));
    },
    getMap: () => map,
    on: onUpdate,
  };
};
