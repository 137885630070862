/**
 * Group data in Array inside an Array.
 */
function partition(data, size) {
  return data.length <= size
    ? [data]
    : [data.slice(0, size)].concat(partition(data.slice(size), size));
}

/**
 * Calculate the probability text based on the probability number.
 */
const calculateProbability = (probabilityNumber) => {
  if (probabilityNumber >= 10) {
    return "High";
  }
  if (probabilityNumber >= 0.1) {
    return "Medium";
  }
  return "Low";
};

/**
 * Set partition size based on the amount of data points.
 * @TODO: Test this together with TOC.
 */
const calculatePartitionSize = (data) => {
  if (data.length > 7000) {
    return 70;
  }
  if (data.length > 6000) {
    return 65;
  }
  if (data.length > 5000) {
    return 60;
  }
  if (data.length > 3000) {
    return 45;
  }
  if (data.length > 2000) {
    return 25;
  }
  if (data.length > 1000) {
    return 17;
  }
  if (data.length > 500) {
    return 12;
  }
  if (data.length > 250) {
    return 6;
  }
  if (data.length > 50) {
    return 3;
  }
  return 1;
};

/**
 * Format the API data into an Array of Arrays so it can be drawn on the map in
 * bulk steps.
 */
const formatData = ({
  latitudes_inland: latitudesInland,
  longitudes_inland: longitudesInland,
  latitudes_offshore: latitudesOffshore,
  longitudes_offshore: longitudesOffshore,
  trajectory_center_lat: centerLatitude,
  trajectory_center_lon: centerLongitude,
  is_polar_trajectory: isPolarTrajectory,
  latitudes_shore: latitudesShore,
  longitudes_shore: longitudesShore,
  probability,
  travelled_distance: travelledDistance,
}) => {
  // Zip inland and offshore data.
  const inland = latitudesInland.map((lat, index) => [
    longitudesInland[index],
    lat,
  ]);
  const shore = latitudesShore.map((lat, index) => [
    longitudesShore[index],
    lat,
  ]);
  const offshore = latitudesOffshore.map((lat, index) => [
    longitudesOffshore[index],
    lat,
  ]);

  // return large Array of Arrays.
  return {
    inland: partition(inland, calculatePartitionSize(inland)),
    shore: partition(shore, calculatePartitionSize(shore)),
    offshore: partition(offshore, calculatePartitionSize(offshore)),

    centerLatLong: [centerLongitude, centerLatitude],
    isPolarTrajectory,
    probability: calculateProbability(probability),
    travelledDistance: new Intl.NumberFormat("en-EN").format(
      Math.round(travelledDistance),
    ),
  };
};

export const getData = async (baseUrl, lat, long) => {
  const url = `${baseUrl}?longitude=${long}&latitude=${lat}`;

  try {
    const response = await fetch(url);

    // If there is a 502, or 400 etc. Return with the correct status code.
    if (!response.ok) {
      return {
        data: {},
        status: response.status,
      };
    }

    const parsedResponse = await response.json();
    return {
      data: formatData(parsedResponse),
      status: response.status,
    };
  } catch {
    // Return error object. Error is handled in the consumer.
    return {
      data: {},
      status: 404,
    };
  }
};
