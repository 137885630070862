import { debounce } from "./util";
import { subscribe } from "./observer";
import { matchesBreakpoint, isMobileHeaderResize } from "./responsive";

let elementStore = {};
const dimensions = {
  windowWidth: null,
  windowHeight: null,
};

/**
 * Resets the dimensions with the current window dimensions
 */
const resetDimensionsValues = () => {
  dimensions.windowHeight = window.innerHeight;
  dimensions.windowWidth = window.innerWidth;
};

/**
 * Called by observer.
 * Set transform value to move the moveable element to the correct section.
 */
const move =
  (moveableElement, allImagesLoaded) =>
  ({ id }) => {
    // If function is executed before all images are loaded.
    if (!elementStore.items) {
      allImagesLoaded.then(() => move(moveableElement)({ id }));
      return;
    }

    const animateIt = () => {
      moveableElement.style.transform = elementStore.items[id].transformValue;
    };
    window.requestAnimationFrame(animateIt);
  };

/**
 * Resets the zoom values to recalculate all transition values correctly.
 */
const resetZoom = (moveableElement) => {
  moveableElement.setAttribute("data-recalculate", "true");
};
/**
 * Reverts the recalculate attribute, illustration will move freely again.
 */
const activateZoom = (moveableElement) => {
  moveableElement.setAttribute("data-recalculate", "false");
};

/**
 * Function is called on initial load and on each time the window resizes.
 * The value is stored to prevent the calculations being made multiple times when scrolling.
 * Calculate the CSS transform value for each target element on the illustration.
 */
const calculateTransformValue = (targetElement, containerElement) => {
  // If it's the "total" element, return specific value to show the total illustration
  if (targetElement.getAttribute("data-twin-id") === "total") {
    return "translate3d(0, 0, 0) scale3d(0.8, 0.8, 0.8)";
  }

  const targetDimensions = targetElement.getBoundingClientRect();
  const containerDimensions = containerElement.getBoundingClientRect();

  // Get all values necessary for the calculations.
  const containerTop = containerDimensions.top;
  const containerHeight = containerDimensions.height;
  const containerWidth = containerDimensions.width;

  const targetTop = targetDimensions.top;
  const targetX = targetDimensions.x;
  const targetHeight = targetDimensions.height;
  const targetWidth = targetDimensions.width;

  // Calculate the container center point, calculated from the top.
  const containerCenterFromWindowTop = containerTop + containerHeight / 2;
  // Calculate the target center point, calculated from the top.
  const targetCenterFromHeight = targetTop + targetHeight / 2;

  // Calculate the amount of px the element need to move (from the top)
  // to bring the target element in the center of the box.
  const moveY = Math.round(
    containerCenterFromWindowTop - targetCenterFromHeight,
  );

  // Calculate the container center point, calculated from the left.
  const singleContainerMargin = (window.innerWidth - containerWidth) / 2;
  const containerCenterFromWindowLeft =
    containerWidth / 2 + singleContainerMargin;
  // Calculate the target center point, calculated from the left.
  const targetCenterFromWidth = targetX + targetWidth / 2;

  // Calculate the amount of px the element need to move (from the left)
  // to bring the target element in the center of the box.
  const moveX = Math.round(
    containerCenterFromWindowLeft - targetCenterFromWidth,
  );

  return `translate3d(${moveX}px, ${moveY}px, 0) scale3d(1.5, 1.5, 1.5)`;
};

/**
 * Creates a store(object) for all elements and transition values needed for the observer to zoom.
 */
const createElementStore = (
  zoomContainer,
  zoomTransitioningElement,
  zoomTargets,
) => {
  const items = zoomTargets.reduce((allItems, targetElement) => {
    const id = targetElement.getAttribute("data-twin-id");

    return {
      ...allItems,
      [id]: {
        targetElement,
        transformValue: calculateTransformValue(targetElement, zoomContainer),
      },
    };
  }, {});

  return {
    containerElement: zoomContainer,
    transitioningElement: zoomTransitioningElement,
    items,
  };
};

/**
 * Called on window resize.
 * Recalculate the transform values and observer options each time the window resizes.
 */
const handleWindowResize =
  (zoomContainer, zoomTransitioningElement, zoomTargets) => (event) => {
    if (isMobileHeaderResize(dimensions.windowHeight, dimensions.windowWidth)) {
      resetDimensionsValues();
      return;
    }
    resetDimensionsValues();

    resetZoom(zoomTransitioningElement);

    // Bail out if the screen is to large.
    if (matchesBreakpoint("mediumLarge")) {
      return;
    }

    // Create new values and reposition the moveable element correctly
    elementStore = createElementStore(
      zoomContainer,
      zoomTransitioningElement,
      zoomTargets,
    );
    activateZoom(zoomTransitioningElement);
  };

/**
 * Default initialize.
 */
const init = (zoomContainer, moveableElement, zoomTargets) => {
  const resize = handleWindowResize(
    zoomContainer,
    moveableElement,
    zoomTargets,
  );

  resize();
  window.addEventListener("resize", debounce(resize, 500));
  window.addEventListener("orientationchange", debounce(resize, 500));
};

/**
 * Get all HTML elements needed.
 */
const cacheElements = (element) => {
  const moveableElementSelector = element.getAttribute(
    "data-plastic-journey-illustration-transitioning-element",
  );
  const zoomTargetSelector = element.getAttribute(
    "data-plastic-journey-illustration-target",
  );
  const imageSelector = element.getAttribute(
    "data-plastic-journey-illustration-image",
  );

  return {
    zoomContainer: element,
    moveableElement: document.querySelector(moveableElementSelector),
    zoomTargets: Array.from(document.querySelectorAll(zoomTargetSelector)),
    images: Array.from(element.querySelectorAll(imageSelector)),
  };
};

export const enhancer = (element) => {
  const { zoomContainer, moveableElement, zoomTargets, images } =
    cacheElements(element);

  const resolvePromiseWhenImageLoaded = (image) =>
    new Promise((resolve) => {
      image.addEventListener("load", resolve);
    });

  const allImagesLoaded = Promise.all(
    images.map(resolvePromiseWhenImageLoaded),
  );

  init(zoomContainer, moveableElement, zoomTargets);

  subscribe(
    "plastic-journey-active-card",
    move(moveableElement, allImagesLoaded),
  );
};
