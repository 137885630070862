import {
  debounce,
  supportsIntersectionObserver,
  prefersNoMotion,
} from "./util";
import { matchesBreakpoint } from "./responsive";

const showElement = (element) => {
  element.setAttribute("aria-hidden", "false");
};
const hideElement = (element) => {
  element.setAttribute("aria-hidden", "true");
};

const appendElementToTargetElement = (mainElement) => (newElement) => {
  mainElement.innerHTML += newElement.outerHTML;
};

/**
 * Executed when the window is larger than the given width.
 * Hide each element original element and make the stickyContainer sticky.
 * @param {Array} sectionElements
 * @param {Element} rootElement
 */
const showSectionsInStickyBox = (sectionElements, rootElement) => {
  sectionElements.forEach(hideElement);
  rootElement.setAttribute("data-sticky", "true");
};

/**
 * Executed when the window is smaller than the given width.
 * Show each original element and make the stickyContainer not sticky.
 * @param {Array} sectionElements
 * @param {Element} rootElement
 */
const showSectionsInOriginalBox = (sectionElements, rootElement) => {
  sectionElements.forEach(showElement);
  rootElement.setAttribute("data-sticky", "false");
};

/**
 * Executes functions to show and hide the correct sections.
 * @param {Array} sectionElements
 * @param {HTMLElement} rootElement
 */
const handleWindowResize = (sectionElements, rootElement) => {
  const windowIsWideEnough = matchesBreakpoint("medium");

  if (windowIsWideEnough) {
    showSectionsInStickyBox(sectionElements, rootElement);
  } else {
    showSectionsInOriginalBox(sectionElements, rootElement);
  }
};

/**
 * Copies all sections to the sticky-box.
 * Only executed once.
 * @param {Array} sectionElements
 * @param {HTMLElement} targetElement
 */
const copySectionsToStickyBox = (sectionElements, targetElement) => {
  sectionElements.forEach(appendElementToTargetElement(targetElement));
};

export const enhancer = (element) => {
  // After the layout is updated, the enhancer play-video-based-on-element-location takes over.
  // This enhancer requires the intersection-observer and position: sticky.
  // Therefore this enhancer will opt out if one of the two is not supported.
  // This enhancer will also opt out if
  // no motion is preferred to keep the video aligned with the section.
  if (!supportsIntersectionObserver() || prefersNoMotion) {
    return;
  }

  const sectionsSelector = element.getAttribute("data-sections");
  const targetSelector = element.getAttribute("data-target");

  const sectionElements = Array.from(
    element.querySelectorAll(sectionsSelector),
  );
  const targetElement = element.querySelector(targetSelector);

  copySectionsToStickyBox(sectionElements, targetElement);

  if (matchesBreakpoint("medium")) {
    showSectionsInStickyBox(sectionElements, element);
  } else {
    showSectionsInOriginalBox(sectionElements, element);
  }

  window.addEventListener(
    "resize",
    debounce(() => handleWindowResize(sectionElements, element), 500),
  );
  window.addEventListener(
    "orientationchange",
    debounce(() => handleWindowResize(sectionElements, element), 500),
  );
};
