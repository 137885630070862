/* eslint-disable no-return-assign, no-undef, no-unused-vars */

import { memoize } from "@grrr/utils";
import { waitForGlobal } from "./util";
import { getDocWidth, matchesBreakpoint } from "./responsive";

const DEFAULT_ZOOM = 3;
const DEFAULT_CENTER = {
  lat: 35.5,
  lon: -138,
};

const CSS_BREAKPOINT = 940;

const MARKER_SELECTOR = ".js-marker";
const MAPBOX_SELECTOR = ".js-mapbox";
const BROWSER_WARNING_SELECTOR = ".js-browser-warning";

const FEED_ATTRIBUTE = "data-feed";
const MAPBOX_ACCESS_TOKEN_ATTRIBUTE = "data-mapbox-access-token";

export const MapboxMap = (container) => {
  const MAPBOX_ACCESS_TOKEN = container.getAttribute(
    MAPBOX_ACCESS_TOKEN_ATTRIBUTE,
  );

  let mapboxMap;

  /**
   * Set global Mapbox access token.
   */
  const setMapboxAccessToken = (token) => (window.mapboxgl.accessToken = token);

  /**
   * Initialize Mapbox and set controls.
   */
  const createMapboxMap = (target) => {
    setMapboxAccessToken(MAPBOX_ACCESS_TOKEN);

    const map = new mapboxgl.Map({
      container: target,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [DEFAULT_CENTER.lon, DEFAULT_CENTER.lat],
      zoom: getDocWidth() < CSS_BREAKPOINT ? DEFAULT_ZOOM - 1 : DEFAULT_ZOOM,
      minZoom: 2,
      maxZoom: 22,
      interactive: true,
      attributionControl: true,
      logoPosition: "bottom-right",
      scrollZoom: false,
      doubleClickZoom: true,
    });

    const controls = [
      new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: "metric",
      }),
    ];
    if (matchesBreakpoint("small")) {
      controls.push(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        }),
      );
    }
    controls.forEach((control) => map.addControl(control));
    return map;
  };

  /**
   * Create a marker, or update if the element already exists.
   */
  const createOrUpdateMarker = ({ marker, lat, lon }) => {
    new mapboxgl.Marker({ element: marker })
      .setLngLat([lon, lat])
      .addTo(mapboxMap);
    marker.setAttribute("aria-hidden", "false");
    const markerSpan = marker.querySelector("span");
  };

  /**
   * Create a trajectory.
   */
  const createTrajectory = (coordinates, systemId) => {
    const id = `system-id-${systemId}`;
    if (mapboxMap.getLayer(id)) {
      mapboxMap.removeLayer(id);
    }
    if (mapboxMap.getSource(id)) {
      mapboxMap.removeSource(id);
    }
    mapboxMap.addLayer({
      id,
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates,
          },
        },
      },
      paint: {
        "line-width": 1,
        "line-color": "#01CBE1",
      },
    });
  };

  /**
   * Calculate map bounds so we can focus safely on a trajectory.
   */
  const getBounds = (coordinates) => {
    return coordinates.reduce(
      (bounds, coord) => {
        return bounds.extend(coord);
      },
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
    );
  };

  /**
   * Focus map on a trajectory.
   */
  const focusOnTrajectory = (coordinates) => {
    const docWidth = getDocWidth();
    const bounds = getBounds(coordinates);
    mapboxMap.fitBounds(bounds, {
      padding: {
        top: docWidth / 10,
        bottom: docWidth < CSS_BREAKPOINT ? docWidth / 10 : docWidth / 10 + 100,
        left: docWidth < CSS_BREAKPOINT ? docWidth / 5 : docWidth / 10,
        right: docWidth / 10,
      },
    });
  };

  /**
   * Check how many days there are in between two timestamps.
   */
  const daysBetweenTimestamps = (t1, t2) =>
    Math.abs(t1 - t2) / (1000 * 60 * 60 * 24);

  /**
   * Create coordinates based on a data limit.
   */
  const createCoordinates = ({ positions, dayLimit }) => {
    const now = new Date();
    return positions
      .filter((entry) => {
        const days = daysBetweenTimestamps(
          now.getTime(),
          Date.parse(entry.time),
        );
        if (typeof dayLimit !== "undefined" && days > dayLimit) {
          return false;
        }
        return true;
      })
      .map((entry) => [entry.longitude, entry.latitude]);
  };

  /**
   * Fetch new data and memoize it.
   */
  const fetchData = memoize(() => {
    const feed = container.getAttribute(FEED_ATTRIBUTE);
    return new Promise((resolve, reject) => {
      fetch(feed, { cache: "reload" })
        .then((response) => {
          if (!response.ok) {
            return reject(response);
          }
          return response.json();
        })
        .then((json) => resolve(json))
        .catch(reject);
    });
  });

  /**
   * Get a system by id from the data.
   *
   * The type of the ID has been changed in the API. Therefore we use a less strict check.
   */
  const getSystemFromData = (systems, systemId) =>
    // eslint-disable-next-line eqeqeq
    systems.find((system) => system.id == systemId);

  /**
   * Update the position for a live marker.
   */
  const fetchDataAndCreateMarker = ({ marker, systemId }) => {
    return new Promise((resolve, reject) => {
      fetchData()
        .then(({ systems }) => {
          const system = getSystemFromData(systems, systemId);
          const firstPosition = system.trajectory[0];
          createOrUpdateMarker({
            marker,
            lat: firstPosition.latitude,
            lon: firstPosition.longitude,
          });
          resolve("Current position fetched and marker created or updated");
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Update the trajectory for a live marker.
   */
  const fetchDataAndCreateTrajectory = ({
    marker,
    systemId,
    trajectoryDaysLimit,
  }) => {
    const trajectoryFocus =
      marker.getAttribute("data-trajectory-focus") === "true";
    return new Promise((resolve, reject) => {
      fetchData()
        .then(({ systems }) => {
          const system = getSystemFromData(systems, systemId);
          const coordinates = createCoordinates({
            positions: system.trajectory,
            dayLimit: trajectoryDaysLimit,
          });
          if (!coordinates.length) {
            resolve("History fetched but trajectory ignored");
            return;
          }
          createTrajectory(coordinates, systemId);
          if (trajectoryFocus) {
            focusOnTrajectory(coordinates);
          }
          resolve("History fetched and trajectory created or updated");
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Update the live marker and its positons.
   * Currently called from wrapper, but data is cached by memoization.
   */
  const updateLiveMarker = (marker) => {
    const systemId = marker.getAttribute("data-system-id");
    const trajectoryDaysLimit = parseInt(
      marker.getAttribute("data-trajectory-days-limit"),
      10,
    );
    return new Promise((resolve, reject) => {
      const fetchAndUpdate = () => {
        Promise.all([
          fetchDataAndCreateMarker({ marker, systemId }),
          fetchDataAndCreateTrajectory({
            marker,
            systemId,
            trajectoryDaysLimit,
          }),
        ])
          .then((result) => {
            fetchData().then(({ systems }) => {
              const system = getSystemFromData(systems, systemId);
              const lastPosition = system.trajectory[0];
              resolve(lastPosition.time);
            });
          })
          .catch((error) => reject(error));
      };
      if (!mapboxMap.loaded || !mapboxMap.isStyleLoaded()) {
        mapboxMap.on("load", (e) => fetchAndUpdate());
        return;
      }
      fetchAndUpdate();
    });
  };

  return {
    init() {
      return new Promise((resolve, reject) => {
        waitForGlobal("mapboxgl", 100)
          .then((mapboxgl) => {
            if (!mapboxgl.supported()) {
              // Show a warning if Mapbox is not supported.
              const warning = container.querySelector(BROWSER_WARNING_SELECTOR);
              warning.setAttribute("aria-hidden", "false");
              return reject(new Error("Mapbox not supported."));
            }
            return createMapboxMap(container.querySelector(MAPBOX_SELECTOR));
          })
          .then((map) => {
            mapboxMap = map;
            const markers = container.querySelectorAll(MARKER_SELECTOR);
            [...markers].forEach((marker) => {
              const lat = marker.getAttribute("data-lat");
              const lon = marker.getAttribute("data-lon");
              createOrUpdateMarker({ marker, lat, lon });
            });
            return resolve("Mapbox loaded.");
          });
      });
    },
    updateLiveMarker,
    createOrUpdateMarker,
  };
};
