const SHARE_OPTIONS = {
  facebook: {
    label: "Facebook",
    url: "https://www.facebook.com/sharer/sharer.php",
    params: [
      {
        name: "u",
        value: "link",
      },
      {
        name: "quote",
        value: "description",
      },
    ],
  },
  twitter: {
    label: "Twitter",
    url: "https://twitter.com/intent/tweet",
    params: [
      {
        name: "url",
        value: "link",
      },
      {
        name: "text",
        value: "description",
      },
    ],
  },
  linkedin: {
    label: "LinkedIn",
    url: "https://www.linkedin.com/shareArticle",
    params: [
      {
        name: "url",
        value: "link",
      },
      {
        name: "source",
        value: "link",
      },
      {
        name: "title",
        value: "title",
      },
      {
        name: "summary",
        value: "description",
      },
      // Also '?mini=true'
    ],
  },
  mail: {
    label: "Mail",
    url: "mailto:",
    params: [
      {
        name: "subject",
        value: "title",
      },
      {
        name: "body",
        value: "link",
      },
    ],
  },
};

/**
 * Generate a shareable link for one of the predefined networks, with the
 * following required sharing options:
 *
 * - link
 * - title
 * - description
 *
 * Note: `URLSearchParams` encodes spaces as `+`, which causes problems in certain
 * email clients. Therefore we use our own implementation with `encodeURIComponent`,
 * as per RFC 1738
 */
export const getShareLink = ({ type, ...sharingOptions }) => {
  const object = SHARE_OPTIONS[type];
  if (!object) {
    return "";
  }
  return object.params.reduce((acc, { name, value }) => {
    const operator = acc === object.url ? "?" : "&";
    return `${acc}${operator}${name}=${encodeURIComponent(
      sharingOptions[value],
    )}`;
  }, `${object.url}`);
};
