import { subscribe } from "./observer";
import { systemsDashboardReceivedData } from "./observer-subjects";
import formatDashboardNumber from "../utils/format-dashboard-number";

/**
 * Called by Observer.
 * Update contents of HTML with the received data and update visibility.
 */
const handleReceiveData =
  (container, totalElement) =>
  ({ totals }) => {
    totalElement.innerHTML = formatDashboardNumber(
      totals.debris_extracted_total,
    );
    container.classList.add("systems-dashboard-meta-preview--show");
  };

export const enhancer = (container) => {
  const totalElement = container.querySelector(".js-total");

  subscribe(
    systemsDashboardReceivedData,
    handleReceiveData(container, totalElement),
  );
};
