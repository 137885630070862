/**
 * Expands/Collaps Word definition items
 */

const showDefinition = (el) => {
  const hiddenDefinition = el.getAttribute("data-definition");
  const visibleDefinition = document.createElement("span");

  visibleDefinition.innerHTML = hiddenDefinition;

  visibleDefinition.classList.add("word-definition__content");

  el.setAttribute("aria-expanded", true);
  el.parentNode.appendChild(visibleDefinition);
};

const hideDefinition = (el) => {
  el.setAttribute("aria-expanded", false);
  const visibleDefinition = el.parentNode.querySelector(
    ".word-definition__content",
  );

  if (visibleDefinition) {
    visibleDefinition.parentNode.removeChild(visibleDefinition);
  }
};

export const handler = (el, e) => {
  e.preventDefault();
  if (el.getAttribute("aria-expanded") === "false") {
    showDefinition(el);
  } else {
    hideDefinition(el);
  }
};
