import { publish, subscribe } from "../observer";
import {
  updateMarkerInfo,
  citizenSciencePiePartMouseEnter,
  citizenSciencePiePartMouseLeave,
} from "../observer-subjects";
import { waitForGlobal } from "../util";
import createChart from "./create-chart";

/**
 * Creates duration label.
 * When the duration is 1, use minute, otherwise use minutes
 */
const createDurationLabel = (duration) => {
  if (duration === 1 || duration === "1") {
    return `${duration} minute`;
  }
  return `${duration} minutes`;
};

/**
 * Called by observer.
 * Remove data element when the mouse leaves the pie chart.
 */
const handlePiePartMouseLeave = (element) => () => {
  element.removeAttribute("data-hover-active");
};
/**
 * Called by observer.
 * Add hover active state to part.
 */
const handlePiePartMouseEnter = (element) => (data) => {
  if (element.getAttribute("data-type") === data) {
    element.setAttribute("data-hover-active", "true");
    return;
  }
  element.setAttribute("data-hover-active", "false");
};

/**
 * Add hover listeners for mouse actions..
 */
const addHoverListeners = (element) => {
  // Add listeners.
  const type = element.getAttribute("data-type");
  element.addEventListener("mouseenter", () =>
    publish(citizenSciencePiePartMouseEnter, type),
  );
  element.addEventListener("mouseleave", () =>
    publish(citizenSciencePiePartMouseLeave, type),
  );
};
/**
 * Subscribe to observer for events by other pieces od the pie.
 */
export const addHoverSubscribes = (element) => {
  // Subscribe to react on hover events.
  subscribe(citizenSciencePiePartMouseEnter, handlePiePartMouseEnter(element));
  subscribe(citizenSciencePiePartMouseLeave, handlePiePartMouseLeave(element));
};

/**
 * Update the legend based on the results.
 */
const updateLegend = (elements, results) => {
  // Reset legends container.
  elements.legendContainer.innerHTML = "";

  // Create legend.
  results.map((result) => {
    // Clone template element.
    const legendItem = elements.legendTemplate.cloneNode(true);

    // Update HTML of template element.
    legendItem.querySelector(".js-legend-name").innerHTML = result.type;
    legendItem.setAttribute("data-type", result.type);
    legendItem.setAttribute("id", result.type);

    // Add listeners to legend item to react on hover
    addHoverListeners(legendItem);
    addHoverSubscribes(legendItem);

    // Append new template element.
    elements.legendContainer.appendChild(legendItem);
  });
};

/**
 * Update the chart based on the results.
 */
const updateChart = (elements, results) => {
  // Reset chart.
  elements.chart.innerHTML = "";

  // Create chart.
  const chart = createChart(elements.container, results, addHoverSubscribes);

  // Append listeners.
  const pieParts = Array.from(
    chart.querySelectorAll('[data-element="pie-part"]'),
  );
  pieParts.forEach(addHoverListeners);
  pieParts.forEach(addHoverSubscribes);

  // Append chart to container element.
  elements.chart.appendChild(chart);
};

/**
 * Called by observer.
 * Update the content of the marker info based on data.
 */
const updateContent = (elements) => (data) => {
  elements.title.innerHTML = data.type;
  elements.id.innerHTML = data.displayId;
  elements.latLong.innerHTML = data.displayLatLong;
  elements.dateTime.innerHTML = data.dateTime;
  elements.dateTime.setAttribute("datetime", data.utcDate);
  elements.duration.innerHTML = createDurationLabel(data.durationInMinutes);
  elements.totalAmount.innerHTML = data.totalItemsCount;

  const results = JSON.parse(data.results);
  updateChart(elements, results);
  updateLegend(elements, results);

  // Optionally show a back button, relevant if a cluster overview is shown.
  elements.backButton.setAttribute(
    "aria-hidden",
    String(data.hideBackButton) || "true",
  );
};

const initializeMarkerInfo = (elements) => () => {
  subscribe(updateMarkerInfo, updateContent(elements));
};

export const enhancer = (element) => {
  const elements = {
    container: element,
    backButton: element.querySelector(".js-back-button"),
    title: element.querySelector(".js-title"),
    id: element.querySelector(".js-id"),
    latLong: element.querySelector(".js-lat-long"),
    dateTime: element.querySelector(".js-date-time"),
    duration: element.querySelector(".js-duration"),
    chart: element.querySelector(".js-pie-chart"),
    totalAmount: element.querySelector(".js-total-amount"),
    legendTemplate: element.querySelector(".js-legend-item").cloneNode(true),
    legendContainer: element.querySelector(".js-legend-container"),
  };

  waitForGlobal("d3", 100).then(initializeMarkerInfo(elements));
};
