import { throttle, uuid } from "@grrr/utils";
import {
  register as registerScrollListener,
  unregister as unregisterScrollListener,
  isVisibleOrScrolledPast,
} from "./scroll-listener";

const SCROLL_KEY = `lazy-feeds-${uuid()}`;

const loadWhenVisible = (el, feeds) => (e) => {
  if (!isVisibleOrScrolledPast(el, 200)) {
    return;
  }
  feeds.forEach((feed) => {
    feed.src = feed.getAttribute("data-src");
  });
  unregisterScrollListener(SCROLL_KEY);
};

export const enhancer = (el) => {
  const feeds = el.querySelectorAll("script[data-src]");
  registerScrollListener(
    SCROLL_KEY,
    throttle(loadWhenVisible(el, [...feeds]), 100),
  );
  loadWhenVisible(el, feeds)();
};
