/**
 * Sort, filter and set the stories to add.
 * Optionally limit the amount of stories to show.
 */
export const getStoriesToAdd = (stories, maxResults, prioId) => {
  const storiesToAdd = maxResults ? stories.slice(0, maxResults) : stories;

  // In case there is an PrioId, add a isHighlighted property.
  if (!prioId) return storiesToAdd;
  return storiesToAdd.map((story) =>
    story.id === prioId
      ? {
          ...story,
          isHighlighted: true,
        }
      : story,
  );
};

export const getPrioId = () => {
  const params = new URL(window.location.href).searchParams;
  return params.get("id");
};
