import { head, prop } from "@grrr/utils";

const getCurrentTabIndex = (tabs) =>
  tabs.findIndex((tab) => tab.getAttribute("aria-selected") === "true");

const toggle = (tabs, target) => {
  tabs.forEach((tab) => {
    const panel = document.getElementById(tab.getAttribute("aria-controls"));
    tab.setAttribute("aria-selected", tab === target);
    panel.setAttribute("aria-hidden", tab !== target);
  });
};

export const enhancer = (container) => {
  const tabs = [...container.querySelectorAll('[role="tab"]')];

  // Attach click listeners to all tabs.
  tabs.forEach((tab) =>
    tab.addEventListener("click", (e) => {
      e.preventDefault();
      toggle(tabs, e.currentTarget);
      e.currentTarget.blur();
    }),
  );

  // Attach left/right key listener to first tab.
  head(tabs).addEventListener("keydown", (e) => {
    const index = getCurrentTabIndex(tabs);
    if (e.key === "ArrowLeft" && index > 0) {
      toggle(tabs, prop(index - 1, tabs));
    } else if (e.key === "ArrowRight" && index < tabs.length - 1) {
      toggle(tabs, prop(index + 1, tabs));
    }
  });
};
