/* eslint-disable no-promise-executor-return */

import { htmlToElement, memoize, preventingDefault } from "@grrr/utils";
import {
  getIntegerLabel,
  getRelativeTimeLabel,
  waitForGlobal,
  getDisplayDate,
} from "./util";
import { matchesBreakpoint } from "./responsive";
import { publish } from "./observer";
import {
  forceClosePopup,
  systemsDashboardReceivedData,
} from "./observer-subjects";
import {
  MapboxData,
  MARKER_LAYER,
  CLUSTER_LAYER,
} from "./systems-dashboard-mapbox-data";
import { MapIntro, SELECTOR as MAP_INTRO_SELECTOR } from "./map-intro";
import { SystemsDashboardInfo } from "./systems-dashboard-info";
import customMarkerBuilder from "./system-dashboard/custom-marker-builder";

const INFO_WRAPPER_SELECTOR = ".js-info-wrapper";
const SUPPORT_WARNING_SELECTOR = ".js-support-warning";

const MAPBOX_TOKEN_ATTRIBUTE = "data-mapbox-token";
const RIVER_FEED_ATTRIBUTE = "data-feed-river";
const OCEAN_FEED_ATTRIBUTE = "data-feed-ocean";

const INTEGER_FIELDS = [
  "debris_extracted_last_30d",
  "debris_extracted_total",
  "kg_extracted",
  "system_02_additional_debris_extracted_total",
];

/**
 * Get a label from a provided object.
 * If no label can be found, a fallback value is returned.
 */
const getLabelFromObject = (key, labels, fallbackValue) => {
  // Format the key in case it contains capitals or spaces.
  const formattedKey = key
    .toLowerCase()
    // Remove the possible "/"
    .replace("/", "")
    // Replace every " " with a "_"
    .split(" ")
    .join("_")
    // It could happen (due to the "/"), that there are two __. This should be one!
    .replace("__", "_");
  return labels[formattedKey] ? labels[formattedKey] : fallbackValue;
};

/**
 * Merge datasets for both ocean an river systems.
 * generation_time and totals have both the same value in each API response,
 * the systems have to be merged into the same array.
 */
const mergeDataSets = (realtimeData) =>
  realtimeData.reduce(
    (collection, item) => {
      return {
        generation_time: item.generation_time,
        totals: item.totals,
        systems: [...collection.systems, ...item.systems],
      };
    },
    { systems: [] },
  );

/**
 * Note: The intro element was later abstracted away for re-use in other maps.
 * @TODO refactor element getter functions and/or move info to its own module?
 */
const SystemsDashboard = ({
  container,
  mapboxgl,
  map,
  sourceName,
  dateLabels,
  cmsContent,
}) => {
  const systemData = [...window.RIVER_SYSTEM_DATA, ...window.OCEAN_SYSTEM_DATA];

  let currentMarkerFeature;
  let currentMarkerNode;
  let additionalMarkerNodes = [];

  const isSourceLoaded = (source) =>
    map.getSource(source) && map.isSourceLoaded(source);
  const isLayerLoaded = (layer) => map.getLayer(layer);

  const mapIntro = new MapIntro(container.querySelector(MAP_INTRO_SELECTOR));

  /**
   * Get elements for intro/info, prevents having to specify lots of selectors.
   */
  const getElement = memoize((parentSelector, target) => {
    return target
      ? container.querySelector(`${parentSelector} [data-element="${target}"]`)
      : container.querySelector(parentSelector);
  });
  const getInfoElement = (target) => getElement(INFO_WRAPPER_SELECTOR, target);

  /**
   * Toggle info element, with helper show/hide functions.
   */
  const toggleInfo = (action) => {
    const hide = action === "hide";
    getInfoElement("close").setAttribute("aria-expanded", !hide);
    getInfoElement().setAttribute("aria-hidden", hide);

    if (hide) {
      // Force close all possible popups.
      publish(forceClosePopup);
    }
  };
  const showInfo = (hash) => {
    toggleInfo("show");
    window.location.hash = hash;
  };
  const hideInfo = () => {
    toggleInfo("hide");
    window.location.hash = "";
  };

  /**
   * Preserve clean copy of the info template to interpolate.
   */
  const getInfoTemplate = memoize(() => getInfoElement("content").outerHTML);

  /**
   * Normalize data (like prettifying integers, adding values).
   *
   * @param data - Realtime and CMS data combined (object).
   */
  const normalizeData = (data) => {
    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (key === "updated_at") {
          acc[key] = getRelativeTimeLabel(new Date(value)) || "unknown";
        } else if (key === "status_date") {
          // Pick the "initial_in_operation_date" whenever it's present and the status is "in_operation".
          const date =
            data.status === "in_operation" && data.initial_in_operation_date
              ? data.initial_in_operation_date
              : value;

          acc[key] = value ? getDisplayDate(new Date(date)) || "unknown" : date;
        } else if (key === "area_covered") {
          // Calculation info
          // 1000 m2 === 0.001mk2.
          // This means / 1000000

          acc[key] = getIntegerLabel(value);
          const footballFieldSquareMeters = 5801;
          const footballFieldSquareKilometers =
            footballFieldSquareMeters / 1000000;
          const inFootballFields = Math.floor(
            value / footballFieldSquareKilometers,
          );

          acc.in_football_fields = new Intl.NumberFormat("en-EN", {}).format(
            inFootballFields,
          );
        } else if (key === "status") {
          acc[key] = value;

          // Add a date_label property with the matching label (from the CMS).
          acc.date_label = getLabelFromObject(value, dateLabels, "Deployed at");
          acc.status_label = acc.status_label || acc.status;
        } else if (key === "type") {
          if (!value) {
            acc[key] = value;
          } else {
            // Replace 1st > 1<sub>st</sub>
            // Replace 2nd > 2<sub>nd</sub>
            // Replace 3rd > 3<sub>rd</sub>

            // Use a regex to select a number followed by the text st/nd/rd to prevent
            // wrapping part of a word in a <sub></sub> like the word "Standard".
            acc[key] = value.replace(
              /\b(\d*\.?\d+) *([st|nd|rd]+)/,
              "$1<sub>$2</sub> ",
            );
          }

          // Check if the data is available AND the CMS setting to show this number is enabled.
          const showDataForRiverSystem =
            (data.debris_extracted_last_30d &&
              data.show_offloaded_in_last_30_days_number) ||
            (data.show_offloaded_in_total_number &&
              data.debris_extracted_total);
          const showDataForOCeanSystem =
            (data.debris_extracted_last_30d &&
              data.show_offloaded_in_last_30_days_number) ||
            (data.show_offloaded_in_total_number &&
              data.debris_extracted_total) ||
            (data.show_area_covered_number && data.area_covered);

          acc.show_numbers =
            value === "Ocean System"
              ? showDataForOCeanSystem
              : showDataForRiverSystem;
        } else if (key === "additional_locations") {
          if (value && value.length) {
            const id = window.location.hash.split("&&").reverse()[0];

            if (id) {
              // Find the additional location and set the interceptor number.
              const additionalLocation = data.additional_locations.find(
                (a) => a.hash === id,
              );
              if (additionalLocation) {
                acc.custom_interceptor_number =
                  additionalLocation.interceptor_number;
              }
            }
          }

          // Map the values from additional_locations either way.
          acc[key] = value;
        } else if (key === "interceptor_number") {
          // Only set the custom_interceptor_number if the if-statement above didn't already set one.
          if (!acc.custom_interceptor_number) {
            acc.custom_interceptor_number = value;
          }
        } else if (
          key === "debris_extracted_total" &&
          "system_02_additional_debris_extracted_total" !== null
        ) {
          const accumulatedTotal =
            data.debris_extracted_total +
            data.system_02_additional_debris_extracted_total;

          acc.debris_extracted_total = new Intl.NumberFormat(
            "en-EN",
            {},
          ).format(accumulatedTotal);
        } else if (INTEGER_FIELDS.includes(key)) {
          acc[key] = getIntegerLabel(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {
        // Set default values for systems to handle both River and Ocean data.
        debris_extracted_last_30d: 0,
        debris_extracted_total: 0,
        area_covered: 0,
        kg_extracted: 0,
        // Set default for API responses without this property.
        paused_message: null,
      },
    );
  };

  /**
   * Fetch realtime data not available in the CMS for a single systems.
   */
  const fetchRealtimeData = memoize((endpoint) => {
    return new Promise((resolve, reject) => {
      fetch(endpoint, { cache: "reload" })
        .then((response) => {
          if (!response.ok) {
            return reject(response);
          }
          return resolve(response.json());
        })
        .catch((error) => reject(error));
    });
  });

  /**
   * Fetch and combine all data (static and realtime).
   */
  const fetchAllData = () => {
    const riverSystemFeatures = window.RIVER_SYSTEM_FEATURES;
    const oceanSystemFeatures = window.OCEAN_SYSTEM_FEATURES;
    const systemFeatures = [...riverSystemFeatures, ...oceanSystemFeatures];

    return new Promise((resolve, reject) => {
      const realTimeRiverData = fetchRealtimeData(
        container.getAttribute(RIVER_FEED_ATTRIBUTE),
      );
      const realTimeOceanData = fetchRealtimeData(
        container.getAttribute(OCEAN_FEED_ATTRIBUTE),
      );

      return Promise.all([realTimeRiverData, realTimeOceanData])
        .then((realtimeData) => {
          // Merge realTimeData data-sets (ocean and river).
          const mergedRealTimeData = mergeDataSets(realtimeData);

          // Merge realtime data and CMS data for al systems.
          const CMSAndRealtimeSystems = mergedRealTimeData.systems.map(
            (item) => {
              const CMSContent = systemFeatures.find(
                // ID Could be a string or a number.
                // eslint-disable-next-line eqeqeq
                (cmsItem) => cmsItem.properties.id == item.id,
              );

              return {
                ...CMSContent,
                ...item,
              };
            },
          );

          // Merge static and realtime data.
          const mergedSystems = CMSAndRealtimeSystems.filter((system) =>
            systemData.find(
              (item) => item.id === system.id || item.hash === system.id,
            ),
          )
            .map((system) => {
              const staticItemData = systemData.find((item) => {
                // item.id for ocean system is always 0, therefore compare bases on hash.
                return item.id === system.id || item.hash === system.id;
              });

              // Only use systems that have static and realtime data.
              // Sometimes the API sends info for an interceptor that isn't
              // published in the CMS. This data should not be shown.
              return system && staticItemData
                ? normalizeData({
                    ...staticItemData,
                    ...system,
                  })
                : {};
            })
            .filter((a) => a);

          resolve({
            ...mergedRealTimeData,
            systems: mergedSystems,
          });
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Interpolate static and realtime data and update the template.
   */
  const setCurrentInfo = (featureId) => {
    fetchAllData().then(({ systems }) => {
      // Use == to compare the same value but of a possible different type.
      // eslint-disable-next-line eqeqeq
      const data = systems.find((item) => item.id == featureId);

      const templateNode = SystemsDashboardInfo({
        template: getInfoTemplate(),
        data: {
          ...data,
          ...cmsContent,
        },
      });

      const docFragment = new DocumentFragment();
      [...templateNode.children()].map((childNode) => {
        docFragment.appendChild(childNode);
      });

      // Empty element before appending
      getInfoElement("content").innerHTML = "";
      getInfoElement("content").appendChild(docFragment);
      getInfoElement().scrollTop = 0;
    });
  };

  /**
   * Reset the current marker.
   */
  const resetCurrentMarkerFeature = () => {
    if (!currentMarkerFeature) {
      return;
    }
    map.removeFeatureState({ source: sourceName, id: currentMarkerFeature.id });
    currentMarkerNode.parentNode.removeChild(currentMarkerNode);
    currentMarkerNode = null;
    currentMarkerFeature = null;

    // Remove additional marker nodes
    additionalMarkerNodes.forEach((additionalMarkerNode) =>
      additionalMarkerNode.parentNode.removeChild(additionalMarkerNode),
    );
    additionalMarkerNodes = [];
  };

  /**
   * Set the current marker feature.
   */
  const setCurrentMarkerFeature = (feature) => {
    resetCurrentMarkerFeature();
    map.setFeatureState(
      { source: sourceName, id: feature.id },
      { active: true },
    );
    currentMarkerFeature = feature;
    currentMarkerNode = htmlToElement(`
      <div class="systems-dashboard__active-marker"></div>
    `);
    new mapboxgl.Marker(currentMarkerNode)
      .setLngLat(feature.geometry.coordinates)
      .addTo(map);

    // Set markers for additional locations.
    if (feature.properties.additionalLocations) {
      // Set markers for additional locations.
      JSON.parse(feature.properties.additionalLocations).forEach(
        (additionalLocation) => {
          // Create new marker.
          const newMarkerNode = htmlToElement(
            `<div class="systems-dashboard__active-marker"></div>`,
          );

          // Push marker to collection.
          additionalMarkerNodes.push(newMarkerNode);

          // Add marker to the map.
          new mapboxgl.Marker(newMarkerNode)
            .setLngLat(additionalLocation)
            .addTo(map);
        },
      );
    }
  };

  /**
   * Set the current marker.
   */
  const setCurrentMarker = (feature) => {
    const coordinates = feature.geometry.coordinates.slice();

    // Get zoom level
    const currentZoomLevel = map.getZoom();
    // Minimal zoom level should be 6. If higher, leave the zoom level like it is.
    const zoomLevel = currentZoomLevel < 6 ? 6 : currentZoomLevel;

    map.flyTo({
      center: [coordinates[0], coordinates[1]],
      speed: 1,
      zoom: zoomLevel,
    });

    setCurrentMarkerFeature(feature);
    setCurrentInfo(feature.properties.id);
    mapIntro.hide();
    showInfo(feature.properties.hash);
  };

  /**
   * Listener for cluster clicks.
   */
  const clusterClickHandler = (e) => {
    const feature = e.features[0];
    const clusterId = feature.properties.cluster_id;
    map
      .getSource(sourceName)
      .getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) {
          return;
        }
        map.easeTo({ center: feature.geometry.coordinates, zoom });
      });
  };

  /**
   * Listener for marker clicks.
   */
  const markerClickHandler = (e) => setCurrentMarker(e.features[0]);

  /**
   * Listener for map clicks (to reset marker focus state).
   */
  const mapClickHandler = (e) => {
    if (!map.getLayer(MARKER_LAYER)) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point, {
      layers: [MARKER_LAYER],
    });
    if (!features.length) {
      hideInfo();
      // Re-center the last active marker on mobile due to closing info popup.
      if (!matchesBreakpoint("small") && currentMarkerFeature) {
        const coordinates = currentMarkerFeature.geometry.coordinates.slice();
        map.panTo(coordinates, {
          duration: 500,
        });
      }
      resetCurrentMarkerFeature();
    }
    mapIntro.hide();
  };

  /**
   * Listener for map mouse events (to set mouse pointer).
   */
  const mapMouseMoveHandler = (e) => {
    if (!map.getLayer(CLUSTER_LAYER) || !map.getLayer(MARKER_LAYER)) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point, {
      layers: [CLUSTER_LAYER, MARKER_LAYER],
    });
    map.getCanvas().style.cursor = features.length ? "pointer" : "";
  };

  /**
   * Attach event listeners to the Mapbox map.
   */
  const attachMapListeners = () => {
    map.on("mousemove", mapMouseMoveHandler);
    map.on("click", CLUSTER_LAYER, clusterClickHandler);
    map.on("click", MARKER_LAYER, markerClickHandler);
    map.on("click", mapClickHandler);
  };

  /**
   * Attach click listeners to UI.
   */
  const attachClickListeners = () => {
    getInfoElement("close").addEventListener(
      "click",
      preventingDefault((e) => {
        hideInfo();
        resetCurrentMarkerFeature();
        getInfoElement("content").innerHTML = "";
      }),
    );
  };

  /**
   * Open initial system when opened via URL.
   * We'll have to wait until the source and layer are loaded.
   */
  const openInitialSystemFromHash = (url, systems) => {
    // When you share an URL from Instagram it is encoded, so we need to decode it to get the hash
    const decodedUrl = decodeURIComponent(url);
    const urlObj = new URL(decodedUrl);
    const hash = urlObj.hash.replace("#", "");

    if (!hash) {
      return;
    }

    // Split the hash. If an additional interceptor is active, the hash will contain 2 parts, split by &&.
    const [hashWithoutAddition, additionalHash] = hash.split("&&");

    const getAndSetFeature = () => {
      const feature = systems.systems.find(
        (s) => s.hash === hashWithoutAddition,
      );
      if (!feature) {
        return;
      }

      // The coodinates of a ocean system live in the first item in the trajectory array,
      // not on the object directly.
      const coodinates = feature.trajectory ? feature.trajectory[0] : feature;

      // Create the additional marker data.
      // Switch is to check if the interceptor is a additional added interceptor or a original one.
      const additionalMarkerData = additionalHash
        ? (() => {
            // find feature of additional hash =
            const correctFeature = feature.additional_locations.find(
              (additionalFeature) => additionalFeature.hash === additionalHash,
            );

            return {
              customHash: hash,
              additionalLocations: JSON.stringify([
                [coodinates.longitude, coodinates.latitude],
                ...feature.additional_locations
                  .filter(
                    // eslint-disable-next-line camelcase
                    (a) =>
                      a.additional_longitude !==
                      correctFeature.additional_longitude,
                  )
                  .map((a) => [a.additional_longitude, a.additional_latitude]),
              ]),
              coordinates: [
                correctFeature.additional_longitude,
                correctFeature.additional_latitude,
              ],
            };
          })()
        : {
            customHash: feature.hash,
            additionalLocations: JSON.stringify(
              feature.additional_locations
                ? feature.additional_locations.map((a) => [
                    a.additional_longitude,
                    a.additional_latitude,
                  ])
                : [],
            ),
            coordinates: [coodinates.longitude, coodinates.latitude],
          };

      // Create custom MapBox structured object here.
      //
      // Reason: We cannot search in the existing markers on the map because
      // a search would only include the visible markers and not all markers are
      // initially visible: markers on the west side of the globe and markers
      // that live behind a cluster (they don't have an id).
      setCurrentMarker(customMarkerBuilder(feature, additionalMarkerData));
    };

    const isLoaded = () =>
      isSourceLoaded(sourceName) && isLayerLoaded(MARKER_LAYER);
    const loadOrWait = () =>
      isLoaded() ? getAndSetFeature() : map.once("render", loadOrWait);

    loadOrWait();
  };

  return {
    init() {
      attachMapListeners();
      attachClickListeners();

      // Initialize generic map intro module.
      mapIntro.init();

      // Preload the realtime data.
      fetchAllData().then((combinedData) => {
        publish(systemsDashboardReceivedData, combinedData);

        // Open initial system if it's specified in the URL.
        openInitialSystemFromHash(window.location, combinedData);
      });

      // Expose map for easier debugging.
      window.SYSTEMS_DASHBOARD = map;
    },
  };
};

export const enhancer = (container) => {
  const dateLabels = JSON.parse(container.getAttribute("data-date-labels"));
  // All other labels from the CMS that are used in the info-box template as variable.
  const cmsContent = JSON.parse(container.getAttribute("data-cms-content"));

  waitForGlobal("mapboxgl", 100).then((mapboxgl) => {
    if (mapboxgl.supported()) {
      // Set up Mapbox data, layers and markers.
      const token = container.getAttribute(MAPBOX_TOKEN_ATTRIBUTE);
      const mapboxData = new MapboxData({ mapboxgl, token });
      // Init interactivity handling.
      mapboxData.init().then(({ map, sourceName }) => {
        const dashboard = new SystemsDashboard({
          container,
          map,
          mapboxgl,
          sourceName,
          dateLabels,
          cmsContent,
        });
        dashboard.init();
      });
    } else {
      // Show a warning if Mapbox is not supported.
      const warning = document.querySelector(SUPPORT_WARNING_SELECTOR);
      warning.setAttribute("aria-hidden", "false");
    }
  });
};
