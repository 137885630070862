import SweetScroll from "sweet-scroll";
import { closest } from "./util";

/**
 * Toggles the job form
 */
export const handler = (el, e) => {
  const button =
    e.target.tagName.toLowerCase() === "button"
      ? e.target
      : closest(e.target, "button");

  const isExpanded = button.getAttribute("aria-expanded") === "true";
  const form = document.querySelector(
    `#${button.getAttribute("aria-controls")}`,
  );
  if (!form) {
    return;
  }
  form.setAttribute("aria-hidden", !!isExpanded);
  button.setAttribute("aria-expanded", !isExpanded);
  button.blur();
  if (isExpanded) {
    return;
  }

  // Scroll to form
  const sweetScroll = new SweetScroll();
  sweetScroll.toElement(form, {
    duration: 500,
    easing: "easeInOutCubic",
    complete: () => {
      button.blur();
    },
  });

  // Focus first input
  if (!Modernizr.toucheventsfixed) {
    form.querySelector("input").focus();
  }
};
